<template> 
  <div class="container">
    <Header />
    <div class="content">
      <h1>What are your resources?</h1>
      <div class="textarea-container">
        <textarea 
          v-model="resource" 
          placeholder="Write Your Resources, e.g. Skills, Values, Career, Interests, Experiences, Qualifications etc."
          maxlength="345">
        </textarea>
        <button @click="submitResource" class="submit-button">Submit</button>
      </div>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: {
    Header
  },
  data() {
    return {
      resource: '',
      error: ''
    };
  },
  async mounted() {
    try {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        const response = await axiosInstance.get('/resource', {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.success) {
          this.resource = response.data.resource.resource;
        }
      }
    } catch (error) {
      console.error('Failed to load resource:', error);
      this.error = 'Failed to load resource. Please try again later.';
    }
  },
  methods: {
    async submitResource() {
      if (this.resource.trim() === '') {
        this.error = 'Resource cannot be empty!';
        return;
      }

      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          this.error = 'User not authenticated!';
          this.$router.push('/login');
          return;
        }

        const response = await axiosInstance.post('/resource', {
          resource: this.resource.trim(),
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.success) {
          const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
          currentUser.resource = this.resource.trim();
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          this.error = '';
          this.$router.push('/topics');
        } else {
          this.error = 'Failed to save resource. Please try again.';
        }
      } catch (error) {
        console.error('Failed to save resource:', error);
        if (error.response && error.response.status === 401) {
          this.error = 'Authentication failed. Please log in again.';
          localStorage.removeItem('jwtToken');
          this.$router.push('/login');
        } else {
          this.error = 'Failed to save resource. Please try again later.';
        }
      }
    }
  }
};
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  overflow: hidden;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.textarea-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 600px;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.submit-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.submit-button:hover {
  background-color: #555;
}
</style>
