<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Resources" 
      :showGetMoreResourcesButton="true"
      @back="goBackToHome"
      @getMoreResources="goToResourcesPage"
    />
    <div class="content">
      <h1>Create a Topic</h1>
      <div class="create-topic-container">
        <input v-model="newTopic" type="text" placeholder="Write a topic (Maximum 5 Topics per day & maximum 75 characters)" maxlength="75" />
        <button @click="createTopic" class="create-button">Create</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>

      <h2>Search For Topics</h2>
      <input v-model="searchQuery" type="text" placeholder="Search for a topic" @input="saveSearchQuery" />
      <div class="sorting-buttons">
        <button @click="sortByMostRecent" :class="{ active: sortOption === 'mostRecent' }">Most Recent</button>
        <button @click="sortByMostVoted" :class="{ active: sortOption === 'mostVoted' }">Most Voted</button>
      </div>

      <ul class="topics-list">
        <li v-for="topic in sortedTopics" :key="topic._id">
          <router-link :to="{ name: 'topic-in-topics', params: { parentTopicName: topic.name } }">{{ topic.name }}</router-link>
          <div class="actions">
            <button @click="handleFreeVote(topic)">Vote ({{ topic.votes || 0 }})</button>
            <button @click="toggleExtraVote(topic._id)">Extra Votes</button>
            <div v-if="extraVoteVisible === topic._id" class="extra-votes-container">
              <input type="number" v-model="extraVoteCount" :max="extraVotes" min="1" placeholder="Enter extra votes" />
              <button @click="submitExtraVotes(topic)">Submit Extra Votes</button>
            </div>
          </div>
        </li>
      </ul>

      <ul class="topics-list" v-if="sortedTopics.length === 0">
        <li><p>No topics available. Be the first to create one!</p></li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: { Header },
  data() {
    return {
      newTopic: '',
      topics: [],
      searchQuery: localStorage.getItem('searchQuery') || '',
      errorMessage: '',
      currentUser: JSON.parse(localStorage.getItem('currentUser') || '{}').username || 'guest',
      sortOption: 'mostRecent',
      extraVotes: 0,
      extraVoteCount: 0,
      extraVoteVisible: null,
      votesGiven: {},
    };
  },
  computed: {
    sortedTopics() {
      let filtered = this.topics.filter(topic => topic.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
      if (this.sortOption === 'mostVoted') {
        return filtered.sort((a, b) => b.votes - a.votes);
      }
      return filtered.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  },
  async created() {
    await this.loadTopics();
    await this.getExtraVotes();
  },
  methods: {
    async createTopic() {
      if (!this.newTopic.trim()) {
        this.errorMessage = 'Topic name cannot be empty.';
        return;
      }

      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          this.errorMessage = 'You need to log in to create a topic.';
          return;
        }

        const response = await axiosInstance.post('/topics', { name: this.newTopic.trim() }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data && response.data.topic) {
          this.topics.push(response.data.topic);
        }

        this.newTopic = '';
        this.errorMessage = '';
      } catch (error) {
        this.errorMessage = error.response?.data?.message || 'Failed to create topic.';
      }
    },
    async loadTopics() {
      try {
        let response;
        if (this.sortOption === 'mostRecent') {
          response = await axiosInstance.get('/topics/recent');
        } else if (this.sortOption === 'mostVoted') {
          response = await axiosInstance.get('/topics/most-voted');
        }
        this.topics = Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        this.errorMessage = 'Failed to load topics.';
        this.topics = [];
      }
    },

    async handleFreeVote(topic) {
      if (!this.votesGiven[topic._id]) {
        await this.vote(topic, 1);
        this.votesGiven[topic._id] = true;
      } else {
        this.errorMessage = "You have already given your free vote.";
      }
    },

    toggleExtraVote(topicId) {
      this.extraVoteVisible = this.extraVoteVisible === topicId ? null : topicId;
    },

    async submitExtraVotes(topic) {
      const voteCount = parseInt(this.extraVoteCount, 10);

      if (isNaN(voteCount) || voteCount < 1 || voteCount > this.extraVotes) {
        this.errorMessage = "Invalid number of extra votes.";
        return;
      }

      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/topics/${topic._id}/extra-vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        this.extraVotes -= voteCount;
        this.extraVoteVisible = null;
        this.extraVoteCount = 0;
        await this.loadTopics();
      } catch (error) {
        this.errorMessage = 'Error submitting extra votes.';
        console.error('Error submitting extra votes:', error.response?.data || error.message);
      }
    },

    async vote(topic, voteCount = 1) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/topics/${topic._id}/vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        await this.loadTopics();
      } catch (error) {
        this.errorMessage = 'Failed to vote for topic.';
        console.error('Error voting:', error.response?.data || error.message);
      }
    },

    async getExtraVotes() {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axiosInstance.get('/users/extra-votes', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.extraVotes = response.data.extraVotes || 0;
      } catch (error) {
        this.errorMessage = 'Failed to fetch extra votes.';
        console.error('Error fetching extra votes:', error.response?.data || error.message);
      }
    },
    goBackToHome() {
      this.$router.push('/home');
    },
    goToResourcesPage() {
      this.$router.push('/resources');
    },
    async sortByMostRecent() {
      this.sortOption = 'mostRecent';
      await this.loadTopics();
    },
    async sortByMostVoted() {
      this.sortOption = 'mostVoted';
      await this.loadTopics();
    },
    saveSearchQuery() {
      localStorage.setItem('searchQuery', this.searchQuery);
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  padding: 20px;
  overflow-y: auto;
}

.create-topic-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.create-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.create-button:hover {
  background-color: #555;
}

ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.error-message {
  color: red;
}

.sorting-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.sorting-buttons button,
.actions button {
  margin: 0 10px;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sorting-buttons button:hover,
.actions button:hover {
  background-color: #555;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.active {
  background-color: #555;
}

.extra-votes-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.extra-votes-container input {
  width: 100%;
  max-width: 100px;
  padding: 8px;
  box-sizing: border-box;
}

.extra-votes-container button {
  padding: 8px 12px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.extra-votes-container button:hover {
  background-color: #555;
}
</style>
