<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Previous Page" 
      :showLogoutButton="true" 
      @back="goBackToPreviousPage"
    />
    <div class="content">
      <h1>Buy Votes</h1>
      <form @submit.prevent="buyVotes" class="buy-votes-form">
        <label for="votes">Number of Votes ($0.3 per vote):</label>
        <input v-model="voteCount" type="number" id="votes" name="votes" min="1" />

        <label for="email">Email (Required):</label>
        <input v-model="email" type="email" id="email" name="email" required />

        <button class="create-button" type="submit">Purchase Votes</button>
      </form>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axios from '@/axiosConfig.js';

export default {
  components: { Header },
  data() {
    return {
      voteCount: 1,
      email: '',
      errorMessage: '',
    };
  },
  methods: {
    async buyVotes() {
      try {
        const response = await axios.post('/stripe/buy-votes', {
          voteCount: this.voteCount,
          email: this.email,
        });
        window.location.href = response.data.url; // Redirect to Stripe checkout
      } catch (error) {
        this.errorMessage = 'There was an error purchasing votes. Please try again.';
        console.error('Error purchasing votes:', error);
      }
    },
    goBackToPreviousPage() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}

.buy-votes-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.buy-votes-form input {
  width: 100%;
  margin-bottom: 15px;
  padding: 8px;
  font-size: 16px;
}

.create-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.create-button:hover {
  background-color: #555;
}

.error-message {
  color: red;
  margin-top: 10px;
}

h1 {
  margin-bottom: 20px;
}
</style>
