<template>
  <div class="container">
    <Header :showBackButton="true" backText="Get More Resources" :showLogoutButton="true" @back="goBackToResources" />
    <div class="content" ref="content">
      <h1 class="centered-title">{{ todayNews.title }}</h1>
      <pre class="centered-content">{{ todayNewsContent }}</pre>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      todayNews: { title: "October 15 2024", text: "/articles/15oct2024.txt" },
      todayNewsContent: '',
    };
  },
  mounted() {
    this.fetchTodayNewsContent(this.todayNews.text);
  },
  methods: {
    fetchTodayNewsContent(url) {
      return fetch(url)
        .then(response => response.text())
        .then(content => {
          this.todayNewsContent = content;
        });
    },
    goBackToResources() {
      this.$router.push('/resources');
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
}
</style>
