<template>
  <div class="container">
    <Header :showBackButton="true" backText="Back to News" @back="goBackToNews" />
    <div class="content" ref="content">
      <h1 class="centered-title">{{ news.title }}</h1>
      <pre class="centered-content">{{ newsContent }}</pre>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      news: {},
      newsContent: '',
    };
  },
  mounted() {
    const newsId = this.$route.params.newsId;
    this.news = this.getNewsDetails(newsId);
    this.fetchNewsContent(this.news.text).then(() => {
      this.$nextTick(() => {
        const savedPosition = localStorage.getItem(`scrollPosition_News_${newsId}`);
        if (savedPosition) {
          this.$refs.content.scrollTop = savedPosition;
        }
      });
    });
  },
  methods: {
    getNewsDetails(newsId) {
      const newsList = [
      { id: 1, title: "October 13 2024", text: "/articles/13oct2024.txt" },
  { id: 2, title: "October 12 2024", text: "/articles/12oct2024.txt" },
  { id: 3, title: "October 11 2024", text: "/articles/11oct2024.txt" },
  { id: 4, title: "October 10 2024", text: "/articles/10oct2024.txt" },
  { id: 5, title: "October 9 2024", text: "/articles/9oct2024.txt" },
  { id: 6, title: "October 8 2024", text: "/articles/8oct2024.txt" },
  { id: 7, title: "October 7 2024", text: "/articles/7oct2024.txt" },
  { id: 8, title: "October 6 2024", text: "/articles/6oct2024.txt" },
  { id: 9, title: "October 5 2024", text: "/articles/5oct2024.txt" },
  { id: 10, title: "October 4 2024", text: "/articles/4oct2024.txt" },
  { id: 11, title: "October 3 2024", text: "/articles/3oct2024.txt" },
  { id: 12, title: "October 2 2024", text: "/articles/2oct2024.txt" },
  { id: 13, title: "October 1 2024", text: "/articles/1oct2024.txt" },
  { id: 14, title: "September 30 2024", text: "/articles/30sept2024.txt" },
  { id: 15, title: "September 29 2024", text: "/articles/29sept2024.txt" },
  { id: 16, title: "September 28 2024", text: "/articles/28sept2024.txt" },
  { id: 17, title: "September 27 2024", text: "/articles/27sept2024.txt" },
  { id: 18, title: "September 26 2024", text: "/articles/26sept2024.txt" },
  { id: 19, title: "September 25 2024", text: "/articles/25sept2024.txt" },
  { id: 20, title: "September 24 2024", text: "/articles/24sept2024.txt" }
      ];
      return newsList.find(news => news.id === parseInt(newsId));
    },
    fetchNewsContent(url) {
      return fetch(url)
        .then(response => response.text())
        .then(content => {
          this.newsContent = content;
        });
    },
    goBackToNews() {
      this.saveScrollPosition();
      this.$router.push('/news');
    },
    saveScrollPosition() {
      const newsId = this.$route.params.newsId;
      const scrollPosition = this.$refs.content.scrollTop;
      localStorage.setItem(`scrollPosition_News_${newsId}`, scrollPosition);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    next();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
}
</style>
