<template>
  <div class="container">
    <Header
      :showBackButton="true"
      backText="Back to Get More Resources"
      :showLogoutButton="true"
      @back="goBackToResources"
    />
    <div class="content" ref="content">
      <h1>Search For Articles</h1>
      <input type="text" v-model="searchQuery" placeholder="Search for an article" @input="saveSearchQuery" />

      <!-- Genre Buttons -->
      <div class="genres-container">
        <button
          v-for="genre in genres"
          :key="genre"
          @click="filterByGenre(genre)"
          class="genre-button"
        >
          {{ genre }}
        </button>
      </div>

      <!-- Articles List -->
      <ul class="articles-list">
        <li v-for="article in filteredArticles" :key="article.id" class="article-item">
          <router-link :to="{ name: 'article-details', params: { articleId: article.id } }">
            {{ article.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      articles: [
      { "id": 1, "title": "3D Bioprinting", "text": "/articles/3D-bioprinting.txt", "genre": "Science & Technology" },
  { "id": 2, "title": "Abraham Lincoln The Emancipator Of America", "text": "/articles/abraham-lincoln-the-emancipator-of-america.txt", "genre": "History" },
  { "id": 3, "title": "Abraham Maslow The Architect Of The Hierarchy Of Needs", "text": "/articles/abraham-maslow-the-architect-of-the-hierarchy-of-needs.txt", "genre": "Psychology" },
  { "id": 4, "title": "Absurdism The Philosophy Of The Absurd", "text": "/articles/absurdism-the-philosophy-of-the-absurd.txt", "genre": "Philosophy" },
  { "id": 5, "title": "Ada Lovelace The First Computer Programmer", "text": "/articles/ada-lovelace-the-first-computer-programmer.txt", "genre": "Science & Technology" },
  { "id": 6, "title": "Addressing Refugee And Migration Challenges", "text": "/articles/addressing-refugee-and-migration-challenges.txt", "genre": "Political Science & Economics" },
  { "id": 7, "title": "Advance Space Exploration And Research", "text": "/articles/advance-space-exploration-and-research.txt", "genre": "Science & Technology" },
  { "id": 8, "title": "Afghanistan Conflict Inclusive Governance And International Support", "text": "/articles/afghanistan-conflict-inclusive-governance-and-international-support.txt", "genre": "Political Science & Economics" },
  { "id": 9, "title": "AI Powered Financial Services", "text": "/articles/AI-powered-financial-services.txt", "genre": "Business & Finance" },
  { "id": 10, "title": "AI", "text": "/articles/AI.txt", "genre": "Science & Technology" },
  { "id": 11, "title": "Alan Turing The Father Of Computer Science", "text": "/articles/alan-turing-the-father-of-computer-science.txt", "genre": "Science & Technology" },
  { "id": 12, "title": "Albania", "text": "/articles/albania.txt", "genre": "Geography & World Nations" },
  { "id": 13, "title": "Albert Bandura The Theorist Of Social Learning", "text": "/articles/albert-bandura-the-theorist-of-social-learning.txt", "genre": "Psychology" },
  { "id": 14, "title": "Albert Einstein The Architect Of Modern Physics", "text": "/articles/albert-einstein-the-architect-of-modern-physics.txt", "genre": "Science & Technology" },
  { "id": 15, "title": "Albert Ellis The Creator Of Rational Emotive Behavior Therapy", "text": "/articles/albert-ellis-the-creator-of-rational-emotive-behavior-therapy.txt", "genre": "Psychology" },
  { "id": 16, "title": "Alfred Adler The Founder Of Individual Psychology", "text": "/articles/alfred-adler-the-founder-of-individual-psychology.txt", "genre": "Psychology" },
  { "id": 17, "title": "Algebra The Language Of Mathematics", "text": "/articles/algebra-the-language-of-mathematics.txt", "genre": "Science & Mathematics" },
  { "id": 18, "title": "Anarchism The Philosophy Of Stateless Society", "text": "/articles/anarchism-the-philosophy-of-stateless-society.txt", "genre": "Philosophy" },
  { "id": 19, "title": "Anarchism The Politics Of Statelessness", "text": "/articles/anarchism-the-politics-of-statelessness.txt", "genre": "Political Science & Economics" },
  { "id": 20, "title": "Angela Merkel The Chancellor Of Europe", "text": "/articles/angela-merkel-the-chancellor-of-europe.txt", "genre": "Biography & Autobiography" },
  { "id": 21, "title": "Angola", "text": "/articles/angola.txt", "genre": "Geography & World Nations" },
  { "id": 22, "title": "Antimicrobial Resistance", "text": "/articles/antimicrobial-resistance.txt", "genre": "Science & Technology" },
  { "id": 23, "title": "Archaeology and Ancient Civilizations", "text": "/articles/archaeology-and-ancient-civilizations.txt", "genre": "History" },
  { "id": 24, "title": "Archimedes The Mathematician Of The Ancient World", "text": "/articles/archimedes-the-mathematician-of-the-ancient-world.txt", "genre": "Science & Mathematics" },
  { "id": 25, "title": "Argentina", "text": "/articles/argentina.txt", "genre": "Geography & World Nations" },
  { "id": 26, "title": "Aristotle The Philosopher Of Science And Reason", "text": "/articles/aristotle-the-philosopher-of-science-and-reason.txt", "genre": "Philosophy" },
  { "id": 27, "title": "Armenia", "text": "/articles/armenia.txt", "genre": "Geography & World Nations" },
  { "id": 28, "title": "Artificial Intelligence Ethics", "text": "/articles/artificial-intelligence-ethics.txt", "genre": "Ethics & Technology" },
  { "id": 29, "title": "Artificial Intelligence In Healthcare", "text": "/articles/artificial-intelligence-in-healthcare.txt", "genre": "Science & Technology" },
  { "id": 30, "title": "Astrobiology", "text": "/articles/astrobiology.txt", "genre": "Science & Technology" },
  { "id": 31, "title": "Australia", "text": "/articles/australia.txt", "genre": "Geography & World Nations" },
  { "id": 32, "title": "Austria", "text": "/articles/austria.txt", "genre": "Geography & World Nations" },
  { "id": 33, "title": "Autonomous Ships", "text": "/articles/autonomous-ships.txt", "genre": "Science & Technology" },
  { "id": 34, "title": "Azerbaijan", "text": "/articles/azerbaijan.txt", "genre": "Geography & World Nations" },
  { "id": 35, "title": "B.F. Skinner The Behaviorist And Pioneer Of Operant Conditioning", "text": "/articles/b.f.-skinner-the-behaviorist-and-pioneer-of-operant-conditioning.txt", "genre": "Psychology" },
  { "id": 36, "title": "Bahamas", "text": "/articles/bahamas.txt", "genre": "Geography & World Nations" },
  { "id": 37, "title": "Bahaullah The Founder Of The Bahai Faith", "text": "/articles/bahaullah-the-founder-of-the-bahai-faith.txt", "genre": "Religion & Spirituality" },
  { "id": 38, "title": "Bahrain", "text": "/articles/bahrain.txt", "genre": "Geography & World Nations" },
  { "id": 39, "title": "Bangladesh", "text": "/articles/bangladesh.txt", "genre": "Geography & World Nations" },
  { "id": 40, "title": "Barbados", "text": "/articles/barbados.txt", "genre": "Geography & World Nations" },
  { "id": 41, "title": "Behavioral Economics", "text": "/articles/behavioral-economics.txt", "genre": "Business & Finance" },
  { "id": 42, "title": "Behavioral Finance Understanding Investor Psychology", "text": "/articles/behavioral-finance-understanding-investor-psychology.txt", "genre": "Business & Finance" },
  { "id": 43, "title": "Belarus", "text": "/articles/belarus.txt", "genre": "Geography & World Nations" },
  { "id": 44, "title": "Belgium", "text": "/articles/belgium.txt", "genre": "Geography & World Nations" },
  { "id": 45, "title": "Belize", "text": "/articles/belize.txt", "genre": "Geography & World Nations" },
  { "id": 46, "title": "Bhutan", "text": "/articles/bhutan.txt", "genre": "Geography & World Nations" },
  { "id": 47, "title": "Biodegradable Electronics", "text": "/articles/biodegradable-electronics.txt", "genre": "Science & Technology" },
  { "id": 48, "title": "Biodiversity and Ecosystem Services", "text": "/articles/biodiversity-and-ecosystem-services.txt", "genre": "Science & Technology" },
  { "id": 49, "title": "Biodiversity Corridors for Ecosystem Conservation", "text": "/articles/biodiversity-corridors-for-ecosystem-conservation.txt", "genre": "Science & Technology" },
  { "id": 50, "title": "Biodiversity Hotspots", "text": "/articles/biodiversity-hotspots.txt", "genre": "Science & Technology" },
  { "id": 51, "title": "Biodiversity", "text": "/articles/biodiversity.txt", "genre": "Science & Technology" },
  { "id": 52, "title": "Biofabrication", "text": "/articles/biofabrication.txt", "genre": "Science & Technology" },
  { "id": 53, "title": "Biogeography", "text": "/articles/biogeography.txt", "genre": "Science & Technology" },
  { "id": 54, "title": "Biohacking", "text": "/articles/biohacking.txt", "genre": "Science & Technology" },
  { "id": 55, "title": "Bioinformatics", "text": "/articles/bioinformatics.txt", "genre": "Science & Technology" },
  { "id": 56, "title": "Bioluminescence Nature's Light Show", "text": "/articles/bioluminescence-natures-light-show.txt", "genre": "Science & Technology" },
  { "id": 57, "title": "Bioluminescence", "text": "/articles/bioluminescence.txt", "genre": "Science & Technology" },
  { "id": 58, "title": "Biomimicry", "text": "/articles/biomimicry.txt", "genre": "Science & Technology" },
  { "id": 59, "title": "Bioprinting", "text": "/articles/bioprinting.txt", "genre": "Science & Technology" },
  { "id": 60, "title": "Blockchain Beyond Cryptocurrency Revolutionizing Industries", "text": "/articles/blockchain-beyond-cryptocurrency-revolutionizing-industries.txt", "genre": "Science & Technology" },
  { "id": 61, "title": "Blockchain in Healthcare", "text": "/articles/blockchain-in-healthcare.txt", "genre": "Science & Technology" },
  { "id": 62, "title": "Blockchain Technology", "text": "/articles/blockchain-technology.txt", "genre": "Science & Technology" },
  { "id": 63, "title": "Bolivia", "text": "/articles/bolivia.txt", "genre": "Geography & World Nations" },
  { "id": 64, "title": "Bosnia and Herzegovina", "text": "/articles/bosnia-and-herzegovina.txt", "genre": "Geography & World Nations" },
  { "id": 65, "title": "Brazil", "text": "/articles/brazil.txt", "genre": "Geography & World Nations" },
  { "id": 66, "title": "Brunei", "text": "/articles/brunei.txt", "genre": "Geography & World Nations" },
  { "id": 67, "title": "Buddhism: The Path to Enlightenment", "text": "/articles/buddhism-the-path-to-enlightenment.txt", "genre": "Religion & Spirituality" },
  { "id": 68, "title": "Burkina Faso", "text": "/articles/burkina-faso.txt", "genre": "Geography & World Nations" },
  { "id": 69, "title": "Calculus: The Mathematics of Change", "text": "/articles/calculus-the-mathematics-of-change.txt", "genre": "Science & Mathematics" },
  { "id": 70, "title": "Cambodia", "text": "/articles/cambodia.txt", "genre": "Geography & World Nations" },
  { "id": 71, "title": "Canada", "text": "/articles/canada.txt", "genre": "Geography & World Nations" },
  { "id": 72, "title": "Capitalism: The Free-Market Economy", "text": "/articles/capitalism-the-free-market-economy.txt", "genre": "Political Science & Economics" },
  { "id": 73, "title": "Carbon Capture and Storage", "text": "/articles/carbon-capture-and-storage.txt", "genre": "Science & Technology" },
  { "id": 74, "title": "Carbon Nanotubes", "text": "/articles/carbon-nanotubes.txt", "genre": "Science & Technology" },
  { "id": 75, "title": "Carl Friedrich Gauss: The Prince of Mathematicians", "text": "/articles/carl-friedrich-gauss-the-prince-of-mathematicians.txt", "genre": "Science & Mathematics" },
  { "id": 76, "title": "Carl Jung: The Architect of Analytical Psychology", "text": "/articles/carl-jung-the-architect-of-analytical-psychology.txt", "genre": "Psychology" },
  { "id": 77, "title": "Carl Rogers: The Humanistic Psychologist", "text": "/articles/carl-rogers-the-humanistic-psychologist.txt", "genre": "Psychology" },
  { "id": 78, "title": "Carl Sagan: The Cosmic Communicator", "text": "/articles/carl-sagan-the-cosmic-communicator.txt", "genre": "Science & Technology" },
  { "id": 79, "title": "Catherine the Great: The Enlightened Empress of Russia", "text": "/articles/catherine-the-great-the-enlightened-empress-of-russia.txt", "genre": "History" },
  { "id": 80, "title": "Central African Republic (CAR)", "text": "/articles/central-african-republic-(car).txt", "genre": "Geography & World Nations" },
  { "id": 81, "title": "Chad", "text": "/articles/chad.txt", "genre": "Geography & World Nations" },
  { "id": 82, "title": "Chile", "text": "/articles/chile.txt", "genre": "Geography & World Nations" },
  { "id": 83, "title": "China", "text": "/articles/china.txt", "genre": "Geography & World Nations" },
  { "id": 84, "title": "Christianity: The Faith in Christ", "text": "/articles/christianity-the-faith-in-christ.txt", "genre": "Religion & Spirituality" },
  { "id": 85, "title": "Circular Economy for Waste Reduction", "text": "/articles/circular-economy-for-waste-reduction.txt", "genre": "Environmental Science" },
  { "id": 86, "title": "Citizen Science", "text": "/articles/citizen-science.txt", "genre": "Science & Technology" },
  { "id": 87, "title": "Cleopatra VII: The Last Pharaoh of Egypt", "text": "/articles/cleopatra-VII-the-last-pharaoh-of-egypt.txt", "genre": "History" },
  { "id": 88, "title": "Climate Change Adaption", "text": "/articles/climate-change-adaption.txt", "genre": "Environmental Science" },
  { "id": 89, "title": "Climate Change", "text": "/articles/climate-change.txt", "genre": "Environmental Science" },
  { "id": 90, "title": "Climate Engineering", "text": "/articles/climate-engineering.txt", "genre": "Environmental Science" },
  { "id": 91, "title": "Cognitive Biases in Decision Making", "text": "/articles/cognitive-biases-in-decision-making.txt", "genre": "Psychology" },
  { "id": 92, "title": "Colombia", "text": "/articles/colombia.txt", "genre": "Geography & World Nations" },
  { "id": 93, "title": "Color Science and Perception", "text": "/articles/color-science-and-perception.txt", "genre": "Science & Technology" },
  { "id": 94, "title": "Community-Based Renewable Energy Initiatives", "text": "/articles/community-based-renewable-energy-initiatives.txt", "genre": "Environmental Science" },
  { "id": 95, "title": "Comoros", "text": "/articles/comoros.txt", "genre": "Geography & World Nations" },
  { "id": 96, "title": "Complex Analysis: The Study of Complex Numbers and Functions", "text": "/articles/complex-analysis-the-study-of-complex-numbers-and-functions.txt", "genre": "Science & Mathematics" },
  { "id": 97, "title": "Confucius: The Sage of China", "text": "/articles/confucius-the-sage-of-china.txt", "genre": "Philosophy" },
  { "id": 98, "title": "Conservatism: The Tradition of Stability and Order", "text": "/articles/conservatism-the-tradition-of-stability-and-order.txt", "genre": "Political Science & Economics" },
  { "id": 99, "title": "Conservatism: Tradition and Stability", "text": "/articles/conservatism-tradition-and-stability.txt", "genre": "Political Science & Economics" },
  { "id": 100, "title": "Corporate Social Responsibility (CSR): Business for a Better World", "text": "/articles/corporate-social-responsibility-(CSR)-business-for-a-better-world.txt", "genre": "Business & Finance" },
 { "id": 101, "title": "Costa Rica", "text": "/articles/costa-rica.txt", "genre": "Geography & World Nations" },
  { "id": 102, "title": "Create a Supportive Environment", "text": "/articles/create-a-supportive-environment.txt", "genre": "Self-Help & Personal Development" },
  { "id": 103, "title": "Croatia", "text": "/articles/croatia.txt", "genre": "Geography & World Nations" },
  { "id": 104, "title": "Crowdfunding: Democratizing Fundraising and Investment", "text": "/articles/crowdfunding-democratizing-fundraising-and-investment.txt", "genre": "Business & Finance" },
  { "id": 105, "title": "Cryptocurrency: The Digital Revolution in Finance", "text": "/articles/cryptocurrency-the-digital-revolution-in-finance.txt", "genre": "Business & Finance" },
  { "id": 106, "title": "Cryptocurrency", "text": "/articles/cryptocurrency.txt", "genre": "Business & Finance" },
  { "id": 107, "title": "Cryptography: The Art of Secure Communication", "text": "/articles/cryptography-the-art-of-secure-communication.txt", "genre": "Science & Technology" },
  { "id": 108, "title": "Cryptography", "text": "/articles/cryptography.txt", "genre": "Science & Technology" },
  { "id": 109, "title": "Cultivate Positive Relationships", "text": "/articles/cultivate-positive-relationships.txt", "genre": "Self-Help & Personal Development" },
  { "id": 110, "title": "Cultural Astronomy and Human Society", "text": "/articles/cultural-astronomy-and-human-society.txt", "genre": "Science & Technology" },
  { "id": 111, "title": "Cultural Astronomy", "text": "/articles/cultural-astronomy.txt", "genre": "Science & Technology" },
  { "id": 112, "title": "Cultural Heritage Preservation", "text": "/articles/cultural-heritage-preservation.txt", "genre": "History & Culture" },
  { "id": 113, "title": "Cultural Impact of Mythology", "text": "/articles/cultural-impact-of-mythology.txt", "genre": "Mythology & Legends" },
  { "id": 114, "title": "Cultural Sustainability", "text": "/articles/cultural-sustainability.txt", "genre": "Environmental Science" },
  { "id": 115, "title": "Cyber-Physical Systems", "text": "/articles/cyber-physical-systems.txt", "genre": "Science & Technology" },
  { "id": 116, "title": "Cybersecurity Cooperation for Global Security", "text": "/articles/cybersecurity-cooperation-for-global-security.txt", "genre": "Science & Technology" },
  { "id": 117, "title": "Cybersecurity in the Digital Age", "text": "/articles/cybersecurity-in-the-digital-age.txt", "genre": "Science & Technology" },
  { "id": 118, "title": "Cybersecurity", "text": "/articles/cybersecurity.txt", "genre": "Science & Technology" },
  { "id": 119, "title": "Cyprus", "text": "/articles/cyprus.txt", "genre": "Geography & World Nations" },
  { "id": 120, "title": "Dark Matter and Dark Energy", "text": "/articles/dark-matter-and-dark-energy.txt", "genre": "Science & Technology" },
  { "id": 121, "title": "Dark Tourism Ethics and Controversies", "text": "/articles/dark-tourism-ethics-and-controversies.txt", "genre": "Ethics & Philosophy" },
  { "id": 122, "title": "Dark Tourism", "text": "/articles/dark-tourism.txt", "genre": "Travel & Tourism" },
  { "id": 123, "title": "Data Sovereignty", "text": "/articles/data-sovereignty.txt", "genre": "Science & Technology" },
  { "id": 124, "title": "Decentralized Energy Grids for Renewable Power", "text": "/articles/decentralized-energy-grids-for-renewable-power.txt", "genre": "Environmental Science" },
  { "id": 125, "title": "Democracy: Governance by the People", "text": "/articles/democracy-governance-by-the-people.txt", "genre": "Political Science & Economics" },
  { "id": 126, "title": "Develop a Healthy Routine", "text": "/articles/develop-a-healthy-routine.txt", "genre": "Self-Help & Personal Development" },
  { "id": 127, "title": "Develop Technologies for Cosmic Sustainability", "text": "/articles/develop-technologies-for-cosmic-sustainability.txt", "genre": "Science & Technology" },
  { "id": 128, "title": "Differential Equations: Modeling Change and Dynamics", "text": "/articles/differential-equations-modeling-change-and-dynamics.txt", "genre": "Science & Mathematics" },
  { "id": 129, "title": "Digital Detox", "text": "/articles/digital-detox.txt", "genre": "Self-Help & Personal Development" },
  { "id": 130, "title": "Digital Diplomacy for Global Cooperation", "text": "/articles/digital-diplomacy-for-global-cooperation.txt", "genre": "Political Science & International Relations" },
  { "id": 131, "title": "Digital Fabrication", "text": "/articles/digital-fabrication.txt", "genre": "Science & Technology" },
  { "id": 132, "title": "Digital Humanities", "text": "/articles/digital-humanities.txt", "genre": "Arts & Humanities" },
  { "id": 133, "title": "Digital Literacy and Inclusion for a Knowledge-Based Society", "text": "/articles/digital-literacy-and-inclusion-for-a-knowledge-based-society.txt", "genre": "Education & Learning" },
  { "id": 134, "title": "Digital Minimalism", "text": "/articles/digital-minimalism.txt", "genre": "Self-Help & Personal Development" },
  { "id": 135, "title": "Digital Nomadism", "text": "/articles/digital-nomadism.txt", "genre": "Self-Help & Personal Development" },
  { "id": 136, "title": "Digital Therapeutics", "text": "/articles/digital-therapeutics.txt", "genre": "Health & Wellness" },
  { "id": 137, "title": "Digital Twins in Healthcare", "text": "/articles/digital-twins-in-healthcare.txt", "genre": "Science & Technology" },
  { "id": 138, "title": "Diplomatic Engagement for Conflict Resolution", "text": "/articles/diplomatic-engagement-for-conflict-resolution.txt", "genre": "Political Science & International Relations" },
  { "id": 139, "title": "Djibouti", "text": "/articles/djibouti.txt", "genre": "Geography & World Nations" },
  { "id": 140, "title": "East Timor (Timor-Leste)", "text": "/articles/east-timor-(timor-leste).txt", "genre": "Geography & World Nations" },
  { "id": 141, "title": "Eco-Friendly Transportation", "text": "/articles/eco-friendly-transportation.txt", "genre": "Environmental Science" },
  { "id": 142, "title": "Edge Computing", "text": "/articles/edge-computing.txt", "genre": "Science & Technology" },
  { "id": 143, "title": "Egypt", "text": "/articles/egypt.txt", "genre": "Geography & World Nations" },
  { "id": 144, "title": "Elizabeth Loftus: The Expert on Memory and False Memories", "text": "/articles/elizabeth-loftus-the-expert-on-memory-and-false-memories.txt", "genre": "Psychology" },
  { "id": 145, "title": "Elon Musk: The Entrepreneur of the Future", "text": "/articles/elon-musk-the-entrepreneur-of-the-future.txt", "genre": "Business & Finance" },
  { "id": 146, "title": "Embrace Global Cooperation and Unity", "text": "/articles/embrace-global-cooperation-and-unity.txt", "genre": "Political Science & International Relations" },
  { "id": 147, "title": "Emmy Noether: The Mathematician of Symmetry and Invariance", "text": "/articles/emmy-noether-the-mathematician-of-symmetry-and-invariance.txt", "genre": "Science & Mathematics" },
  { "id": 148, "title": "Emotional Intelligence in Leadership", "text": "/articles/emotional-intelligence-in-leadership.txt", "genre": "Psychology" },
  { "id": 149, "title": "Emotional Intelligence", "text": "/articles/emotional-intelligence.txt", "genre": "Psychology" },
  { "id": 150, "title": "Engage in Lifelong Learning", "text": "/articles/engage-in-lifelong-learning.txt", "genre": "Education & Learning" },
  { "id": 151, "title": "Enhance Global Health Security", "text": "/articles/enhance-global-health-security.txt", "genre": "Health & Wellness" },
  { "id": 152, "title": "Ensure Equitable Access to Space", "text": "/articles/ensure-equitable-access-to-space.txt", "genre": "Science & Technology" },
  { "id": 153, "title": "Environmental Politics: The Politics of the Planet", "text": "/articles/environmental-politics-the-politics-of-the-planet.txt", "genre": "Environmental Science" },
  { "id": 154, "title": "Epigenetics", "text": "/articles/epigenetics.txt", "genre": "Science & Technology" },
  { "id": 155, "title": "Equatorial Guinea", "text": "/articles/equatorial-guinea.txt", "genre": "Geography & World Nations" },
  { "id": 156, "title": "Erik Erikson: The Theorist of Psychosocial Development", "text": "/articles/erik-erikson-the-theorist-of-psychosocial-development.txt", "genre": "Psychology" },
  { "id": 157, "title": "Eritrea", "text": "/articles/eritrea.txt", "genre": "Geography & World Nations" },
  { "id": 158, "title": "Establish Ethical Frameworks for Space Activities", "text": "/articles/establish-ethical-frameworks-for-space-activities.txt", "genre": "Science & Technology" },
  { "id": 159, "title": "Establish Regional Security Frameworks", "text": "/articles/establish-regional-security-frameworks.txt", "genre": "Political Science & International Relations" },
  { "id": 160, "title": "Estonia", "text": "/articles/estonia.txt", "genre": "Geography & World Nations" },
  { "id": 161, "title": "Eswatini (Swaziland)", "text": "/articles/eswatini-(swaziland).txt", "genre": "Geography & World Nations" },
  { "id": 162, "title": "Ethical AI", "text": "/articles/ethical-ai.txt", "genre": "Ethics & Philosophy" },
  { "id": 163, "title": "Ethical Consumerism", "text": "/articles/ethical-consumerism.txt", "genre": "Ethics & Philosophy" },
  { "id": 164, "title": "Ethical Hacking", "text": "/articles/ethical-hacking.txt", "genre": "Science & Technology" },
  { "id": 165, "title": "Ethiopia", "text": "/articles/ethiopia.txt", "genre": "Geography & World Nations" },
  { "id": 166, "title": "Ethiopia's Tigray Conflict: Humanitarian Access and National Dialogue", "text": "/articles/ethiopias-tigray-conflict-humanitarian-access-and-national-dialogue.txt", "genre": "Political Science & International Relations" },
  { "id": 167, "title": "Euclid: The Father of Geometry", "text": "/articles/euclid-the-father-of-geometry.txt", "genre": "Science & Mathematics" },
  { "id": 168, "title": "Evolution of Public Health Systems", "text": "/articles/evolution-of-public-health-systems.txt", "genre": "Health & Wellness" },
  { "id": 169, "title": "Evolution of Space Telescopes", "text": "/articles/evolution-of-space-telescopes.txt", "genre": "Science & Technology" },
  { "id": 170, "title": "Evolution: The Mechanism of Biological Diversity", "text": "/articles/evolution-the-mechanism-of-biological-diversity.txt", "genre": "Science & Technology" },
  { "id": 171, "title": "Existentialism: The Philosophy of Existence", "text": "/articles/existentialism-the-philosophy-of-existence.txt", "genre": "Ethics & Philosophy" },
  { "id": 172, "title": "Exoplanet Exploration", "text": "/articles/exoplanet-exploration.txt", "genre": "Science & Technology" },
  { "id": 173, "title": "Expand Cosmic Education and Public Engagement", "text": "/articles/expand-cosmic-education-and-public-engagement.txt", "genre": "Science & Technology" },
  { "id": 174, "title": "Explore and Preserve Cosmic Heritage", "text": "/articles/explore-and-preserve-cosmic-heritage.txt", "genre": "Science & Technology" },
  { "id": 175, "title": "Fascism: The Ideology of Totalitarian Nationalism", "text": "/articles/fascism-the-ideology-of-totalitarian-nationalism.txt", "genre": "Political Science & Economics" },
  { "id": 176, "title": "Federalism: The Balance of Power", "text": "/articles/federalism-the-balance-of-power.txt", "genre": "Political Science & Economics" },
  { "id": 177, "title": "Fiji", "text": "/articles/fiji.txt", "genre": "Geography & World Nations" },
  { "id": 178, "title": "Finland", "text": "/articles/finland.txt", "genre": "Geography & World Nations" },
  { "id": 179, "title": "Florence Nightingale: The Founder of Modern Nursing", "text": "/articles/florence-nightingale-the-founder-of-modern-nursing.txt", "genre": "Health & Wellness" },
  { "id": 180, "title": "Food Sovereignty", "text": "/articles/food-sovereignty.txt", "genre": "Environmental Science" },
  { "id": 181, "title": "Forensic Anthropology", "text": "/articles/forensic-anthropology.txt", "genre": "Science & Technology" },
  { "id": 182, "title": "Forensic Entomology: Solving Crimes with Insects", "text": "/articles/forensic-entomology-solving-crimes-with-insects.txt", "genre": "Science & Technology" },
  { "id": 183, "title": "Fractals: The Geometry of Nature", "text": "/articles/fractals-the-geometry-of-nature.txt", "genre": "Science & Mathematics" },
  { "id": 184, "title": "France", "text": "/articles/france.txt", "genre": "Geography & World Nations" },
  { "id": 185, "title": "Franklin D. Roosevelt: The Architect of the New Deal", "text": "/articles/franklin-d.-roosevelt-the-architect-of-the-new-deal.txt", "genre": "Political Science & Economics" },
  { "id": 186, "title": "Frida Kahlo: Icon of Art and Resilience", "text": "/articles/frida-kahlo-icon-of-art-and-resilience.txt", "genre": "Arts & Humanities" },
  { "id": 187, "title": "Friedrich Nietzsche: The Philosopher of Will and Power", "text": "/articles/friedrich-nietzsche-the-philosopher-of-will-and-power.txt", "genre": "Ethics & Philosophy" },
  { "id": 188, "title": "Gabon", "text": "/articles/gabon.txt", "genre": "Geography & World Nations" },
  { "id": 189, "title": "Gambia", "text": "/articles/gambia.txt", "genre": "Geography & World Nations" },
  { "id": 190, "title": "Game Theory: The Mathematics of Strategy", "text": "/articles/game-theory-the-mathematics-of-strategy.txt", "genre": "Science & Mathematics" },
  { "id": 191, "title": "Gamification in Education", "text": "/articles/gamification-in-education.txt", "genre": "Education & Learning" },
  { "id": 192, "title": "Gautama Buddha: The Enlightened One", "text": "/articles/gautama-buddha-the-enlightened-one.txt", "genre": "Religion & Spirituality" },
  { "id": 193, "title": "Genetic Counseling: Ethics and Practice", "text": "/articles/genetic-counseling-ethics-and-practice.txt", "genre": "Health & Wellness" },
  { "id": 194, "title": "Genetic Counseling", "text": "/articles/genetic-counseling.txt", "genre": "Health & Wellness" },
  { "id": 195, "title": "Genetic Engineering in Agriculture", "text": "/articles/genetic-engineering-in-agriculture.txt", "genre": "Environmental Science" },
  { "id": 196, "title": "Genetic Engineering", "text": "/articles/genetic-engineering.txt", "genre": "Science & Technology" },
  { "id": 197, "title": "Genetic Genealogy", "text": "/articles/genetic-genealogy.txt", "genre": "Science & Technology" },
  { "id": 198, "title": "Genetics: The Blueprint of Life", "text": "/articles/genetics-the-blueprint-of-life.txt", "genre": "Science & Technology" },
  { "id": 199, "title": "Genetics", "text": "/articles/genetics.txt", "genre": "Science & Technology" },
  { "id": 200, "title": "Geometry: The Study of Shapes and Spaces", "text": "/articles/geometry-the-study-of-shapes-and-spaces.txt", "genre": "Science & Mathematics" },
 { "id": 201, "title": "Georgia", "text": "/articles/georgia.txt", "genre": "Geography & World Nations" },
  { "id": 202, "title": "Germany", "text": "/articles/germany.txt", "genre": "Geography & World Nations" },
  { "id": 203, "title": "Gestalt Psychology: The Theory of Perception", "text": "/articles/gestalt-psychology-the-theory-of-perception.txt", "genre": "Psychology" },
  { "id": 204, "title": "Ghana", "text": "/articles/ghana.txt", "genre": "Geography & World Nations" },
  { "id": 205, "title": "Greece", "text": "/articles/greece.txt", "genre": "Geography & World Nations" },
  { "id": 206, "title": "Green Chemistry", "text": "/articles/green-chemistry.txt", "genre": "Environmental Science" },
  { "id": 207, "title": "Group Theory: The Mathematics of Symmetry", "text": "/articles/group-theory-the-mathematics-of-symmetry.txt", "genre": "Science & Mathematics" },
  { "id": 208, "title": "Guinea-Bissau", "text": "/articles/guinea-bissau.txt", "genre": "Geography & World Nations" },
  { "id": 209, "title": "Guinea", "text": "/articles/guinea.txt", "genre": "Geography & World Nations" },
  { "id": 210, "title": "Guru Nanak: The Founder of Sikhism", "text": "/articles/guru-nanak-the-founder-of-sikhism.txt", "genre": "Religion & Spirituality" },
  { "id": 211, "title": "Guyana", "text": "/articles/guyana.txt", "genre": "Geography & World Nations" },
  { "id": 212, "title": "Hans Eysenck: The Theorist of Personality and Intelligence", "text": "/articles/hans-eysenck-the-theorist-of-personality-and-intelligence.txt", "genre": "Psychology" },
  { "id": 213, "title": "Harness and Utilize Space Resources", "text": "/articles/harness-and-utilize-space-resources.txt", "genre": "Science & Technology" },
  { "id": 214, "title": "Harriet Tubman: Conductor of the Underground Railroad", "text": "/articles/harriet-tubman-conductor-of-the-underground-railroad.txt", "genre": "History" },
  { "id": 215, "title": "Harry Harlow: The Researcher of Attachment and Love", "text": "/articles/harry-harlow-the-researcher-of-attachment-and-love.txt", "genre": "Psychology" },
  { "id": 216, "title": "Hedy Lamarr: Inventor and Hollywood Star", "text": "/articles/hedy-lamarr-inventor-and-hollywood-star.txt", "genre": "Science & Technology" },
  { "id": 217, "title": "Henri Poincaré: The Last Universalist", "text": "/articles/henri-poincare-the-last-universalist.txt", "genre": "Science & Mathematics" },
  { "id": 218, "title": "Hinduism: The Ancient Spiritual Tradition", "text": "/articles/hinduism-the-ancient-spiritual-tradition.txt", "genre": "Religion & Spirituality" },
  { "id": 219, "title": "History and Impact of Jazz Music", "text": "/articles/history-and-impact-of-jazz-music.txt", "genre": "Arts & Humanities" },
  { "id": 220, "title": "Honduras", "text": "/articles/honduras.txt", "genre": "Geography & World Nations" },
  { "id": 221, "title": "Howard Schultz: The Mind Behind Starbucks", "text": "/articles/howard-schultz-the-mind-behind-starbucks.txt", "genre": "Business & Finance" },
  { "id": 222, "title": "Human Augmentation", "text": "/articles/human-augmentation.txt", "genre": "Science & Technology" },
  { "id": 223, "title": "Human Cloning", "text": "/articles/human-cloning.txt", "genre": "Science & Technology" },
  { "id": 224, "title": "Human-Machine Interface (HMI) and the Future of Interaction", "text": "/articles/human-machine-interface-(HMI)-and-the-future-of-interaction.txt", "genre": "Science & Technology" },
  { "id": 225, "title": "Human-Machine Interface", "text": "/articles/human-machine-interface.txt", "genre": "Science & Technology" },
  { "id": 226, "title": "Hungary", "text": "/articles/hungary.txt", "genre": "Geography & World Nations" },
  { "id": 227, "title": "Hypatia of Alexandria: The First Female Mathematician", "text": "/articles/hypatia-of-alexandria-the-first-female-mathematician.txt", "genre": "Science & Mathematics" },
  { "id": 228, "title": "Iceland", "text": "/articles/iceland.txt", "genre": "Geography & World Nations" },
  { "id": 229, "title": "Immanuel Kant: The Philosopher of the Enlightenment", "text": "/articles/immanuel-kant-the-philosopher-of-the-enlightenment.txt", "genre": "Philosophy" },
  { "id": 230, "title": "Impressionism", "text": "/articles/impressionism.txt", "genre": "Arts & Humanities" },
  { "id": 231, "title": "India", "text": "/articles/india.txt", "genre": "Geography & World Nations" },
  { "id": 232, "title": "Indonesia", "text": "/articles/indonesia.txt", "genre": "Geography & World Nations" },
  { "id": 233, "title": "Indra Nooyi: The Trailblazer in Corporate Leadership", "text": "/articles/indra-nooyi-the-trailblazer-in-corporate-leadership.txt", "genre": "Business & Finance" },
  { "id": 234, "title": "Initial Public Offering (IPO): Taking a Company Public", "text": "/articles/initial-public-offering-(IPO)-taking-a-company-public.txt", "genre": "Business & Finance" },
  { "id": 235, "title": "Iran", "text": "/articles/iran.txt", "genre": "Geography & World Nations" },
  { "id": 236, "title": "Ireland", "text": "/articles/ireland.txt", "genre": "Geography & World Nations" },
  { "id": 237, "title": "Isaac Newton: The Father of Classical Physics", "text": "/articles/isaac-newton-the-father-of-classical-physics.txt", "genre": "Science & Mathematics" },
  { "id": 238, "title": "Islam: The Path of Submission", "text": "/articles/islam-the-path-of-submission.txt", "genre": "Religion & Spirituality" },
  { "id": 239, "title": "Israeli-Palestinian Conflict: Two-State Solution with International Mediation", "text": "/articles/israeli-palestinian-conflict-two-state-solution-with-international-mediation.txt", "genre": "Political Science & International Relations" },
  { "id": 240, "title": "Italy", "text": "/articles/italy.txt", "genre": "Geography & World Nations" },
  { "id": 241, "title": "Ivan Pavlov: The Founder of Classical Conditioning", "text": "/articles/ivan-pavlov-the-founder-of-classical-conditioning.txt", "genre": "Psychology" },
  { "id": 242, "title": "Ivory Coast (Côte d'Ivoire)", "text": "/articles/ivory-coast-(cote-d-ivoire).txt", "genre": "Geography & World Nations" },
  { "id": 243, "title": "Jack Ma: The Architect of E-Commerce in China", "text": "/articles/jack-ma-the-architect-of-e-commerce-in-china.txt", "genre": "Business & Finance" },
  { "id": 244, "title": "Jainism: The Path of Nonviolence", "text": "/articles/jainism-the-path-of-nonviolence.txt", "genre": "Religion & Spirituality" },
  { "id": 245, "title": "Jamaica", "text": "/articles/jamaica.txt", "genre": "Geography & World Nations" },
  { "id": 246, "title": "Jane Goodall: The Advocate for Primatology and Conservation", "text": "/articles/jane-goodall-the-advocate-for-primatology-and-conservation.txt", "genre": "Environmental Science" },
  { "id": 247, "title": "Jane Goodall: The Primatologist and Conservationist", "text": "/articles/jane-goodall-the-primatologist-and-conservationist.txt", "genre": "Environmental Science" },
  { "id": 248, "title": "Japan", "text": "/articles/japan.txt", "genre": "Geography & World Nations" },
  { "id": 249, "title": "Jawaharlal Nehru: The Architect of Modern India", "text": "/articles/jawaharlal-nehru-the-architect-of-modern-india.txt", "genre": "History" },
  { "id": 250, "title": "Jean-Paul Sartre: The Philosopher of Existentialism", "text": "/articles/jean-paul-sartre-the-philosopher-of-existentialism.txt", "genre": "Philosophy" },
{ "id": 251, "title": "Jean Piaget: The Pioneer of Cognitive Development", "text": "/articles/jean-piaget-the-pioneer-of-cognitive-development.txt", "genre": "Psychology" },
  { "id": 252, "title": "Jeff Bezos: The E-Commerce Pioneer", "text": "/articles/jeff-bezos-the-e-commerce-pioneer.txt", "genre": "Business & Finance" },
  { "id": 253, "title": "Jesus Christ: The Central Figure of Christianity", "text": "/articles/jesus-christ-the-central-figure-of-christianity.txt", "genre": "Religion & Spirituality" },
  { "id": 254, "title": "Joan of Arc: The Warrior Saint", "text": "/articles/joan-of-arc-the-warrior-saint.txt", "genre": "History" },
  { "id": 255, "title": "John B. Watson: The Father of Behaviorism", "text": "/articles/john-b.-watson-the-father-of-behaviorism.txt", "genre": "Psychology" },
  { "id": 256, "title": "John Locke: The Philosopher of Liberty and Empiricism", "text": "/articles/john-locke-the-philosopher-of-liberty-and-empiricism.txt", "genre": "Philosophy" },
  { "id": 257, "title": "Jordan", "text": "/articles/jordan.txt", "genre": "Geography & World Nations" },
  { "id": 258, "title": "Judaism: The Covenant and the Law", "text": "/articles/judaism-the-covenant-and-the-law.txt", "genre": "Religion & Spirituality" },
  { "id": 259, "title": "Karen Horney: The Pioneer of Feminine Psychology", "text": "/articles/karen-horney-the-pioneer-of-feminine-psychology.txt", "genre": "Psychology" },
  { "id": 260, "title": "Karl Marx: Architect of Modern Socialism", "text": "/articles/karl-marx-architect-of-modern-socialism.txt", "genre": "Political Science & Economics" },
  { "id": 261, "title": "Kashmir Conflict: Bilateral Dialogue and Confidence Building Measures", "text": "/articles/kashmir-conflict-bilateral-dialogue-and-confidence-building-measures.txt", "genre": "Political Science & International Relations" },
  { "id": 262, "title": "Kenya", "text": "/articles/kenya.txt", "genre": "Geography & World Nations" },
  { "id": 263, "title": "Kiribati", "text": "/articles/kiribati.txt", "genre": "Geography & World Nations" },
  { "id": 264, "title": "Korean Peninsula Conflict: Denuclearization and Peace Treaty", "text": "/articles/korean-peninsula-conflict-denuclearization-and-peace-treaty.txt", "genre": "Political Science & International Relations" },
  { "id": 265, "title": "Kosovo", "text": "/articles/kosovo.txt", "genre": "Geography & World Nations" },
  { "id": 266, "title": "Kuwait", "text": "/articles/kuwait.txt", "genre": "Geography & World Nations" },
  { "id": 267, "title": "Kyrgyzstan", "text": "/articles/kyrgyzstan.txt", "genre": "Geography & World Nations" },
  { "id": 268, "title": "Laos", "text": "/articles/laos.txt", "genre": "Geography & World Nations" },
  { "id": 269, "title": "Laozi: The Founder of Taoism", "text": "/articles/laozi-the-founder-of-taoism.txt", "genre": "Religion & Spirituality" },
  { "id": 270, "title": "Lebanon", "text": "/articles/lebanon.txt", "genre": "Geography & World Nations" },
  { "id": 271, "title": "Leonardo da Vinci: The Renaissance Genius", "text": "/articles/leonardo-da-vinci-the-renaissance-genius.txt", "genre": "Arts & Humanities" },
  { "id": 272, "title": "Leonardo da Vinci", "text": "/articles/leonardo-da-vinci.txt", "genre": "Arts & Humanities" },
  { "id": 273, "title": "Leonardo Fibonacci: The Mathematician Behind the Sequence", "text": "/articles/leonardo-fibonacci-the-mathematician-behind-the-sequence.txt", "genre": "Science & Mathematics" },
  { "id": 274, "title": "Leonardo Fibonacci: The Medieval Mathematician", "text": "/articles/leonardo-fibonacci-the-medieval-mathematician.txt", "genre": "Science & Mathematics" },
  { "id": 275, "title": "Lesotho", "text": "/articles/lesotho.txt", "genre": "Geography & World Nations" },
  { "id": 276, "title": "Lev Vygotsky: The Pioneer of Sociocultural Theory", "text": "/articles/lev-vygotsky-the-pioneer-of-sociocultural-theory.txt", "genre": "Psychology" },
  { "id": 277, "title": "Liberalism: The Ideology of Freedom and Equality", "text": "/articles/liberalism-the-ideology-of-freedom-and-equality.txt", "genre": "Political Science & Economics" },
  { "id": 278, "title": "Liberalism: The Philosophy of Freedom and Equality", "text": "/articles/liberalism-the-philosophy-of-freedom-and-equality.txt", "genre": "Political Science & Economics" },
  { "id": 279, "title": "Liberia", "text": "/articles/liberia.txt", "genre": "Geography & World Nations" },
  { "id": 280, "title": "Liechtenstein", "text": "/articles/liechtenstein.txt", "genre": "Geography & World Nations" },
  { "id": 281, "title": "Linear Algebra: The Mathematics of Vectors and Matrices", "text": "/articles/linear-algebra-the-mathematics-of-vectors-and-matrices.txt", "genre": "Science & Mathematics" },
  { "id": 282, "title": "Luxembourg", "text": "/articles/luxembourg.txt", "genre": "Geography & World Nations" },
  { "id": 283, "title": "Machine Learning", "text": "/articles/machine-learning.txt", "genre": "Science & Technology" },
  { "id": 284, "title": "Madagascar", "text": "/articles/madagascar.txt", "genre": "Geography & World Nations" },
  { "id": 285, "title": "Mahatma Gandhi: Leader of Nonviolent Resistance", "text": "/articles/mahatma-gandhi-leader-of-nonviolent-resistance.txt", "genre": "History" },
  { "id": 286, "title": "Mahatma Gandhi: The Leader of Indian Independence", "text": "/articles/mahatma-gandhi-the-leader-of-indian-independence.txt", "genre": "History" },
  { "id": 287, "title": "Malala Yousafzai: Advocate for Girls' Education", "text": "/articles/malala-yousafzai-advocate-for-girls-education.txt", "genre": "Education & Learning" },
  { "id": 288, "title": "Malawi", "text": "/articles/malawi.txt", "genre": "Geography & World Nations" },
  { "id": 289, "title": "Malaysia", "text": "/articles/malaysia.txt", "genre": "Geography & World Nations" },
  { "id": 290, "title": "Maldives", "text": "/articles/maldives.txt", "genre": "Geography & World Nations" },
  { "id": 291, "title": "Malta", "text": "/articles/malta.txt", "genre": "Geography & World Nations" },
  { "id": 292, "title": "Manage Time Effectively", "text": "/articles/manage-time-effectively.txt", "genre": "Self-Help & Personal Development" },
  { "id": 293, "title": "Mansa Musa: The Wealthiest Emperor in History", "text": "/articles/mansa-musa-the-wealthiest-emperor-in-history.txt", "genre": "History" },
  { "id": 294, "title": "Margaret Thatcher: The Iron Lady of Britain", "text": "/articles/margaret-thatcher-the-iron-lady-of-britain.txt", "genre": "History" },
  { "id": 295, "title": "Marie Antoinette: The Last Queen of France", "text": "/articles/marie-antoinette-the-last-queen-of-france.txt", "genre": "History" },
  { "id": 296, "title": "Marie Curie: Pioneer of Radioactivity", "text": "/articles/marie-curie-pioneer-of-radioactivity.txt", "genre": "Science & Mathematics" },
  { "id": 297, "title": "Marie Curie", "text": "/articles/marie-curie.txt", "genre": "Science & Mathematics" },
  { "id": 298, "title": "Marine Biology", "text": "/articles/marine-biology.txt", "genre": "Science & Mathematics" },
  { "id": 299, "title": "Marshall Islands", "text": "/articles/marshall-islands.txt", "genre": "Geography & World Nations" },
  { "id": 300, "title": "Marxism: The Theory of Class Struggle", "text": "/articles/marxism-the-theory-of-class-struggle.txt", "genre": "Political Science & Economics" },
{ "id": 301, "title": "Mary Wollstonecraft: Pioneer of Feminist Thought", "text": "/articles/mary-wollstonecraft-pioneer-of-feminist-thought.txt", "genre": "Philosophy" },
  { "id": 302, "title": "Mathematical Logic: The Foundation of Mathematical Reasoning", "text": "/articles/mathematical-logic-the-foundation-of-mathematical-reasoning.txt", "genre": "Science & Mathematics" },
  { "id": 303, "title": "Mathematical Logic: The Foundation of Reasoning", "text": "/articles/mathematical-logic-the-foundation-of-reasoning.txt", "genre": "Science & Mathematics" },
  { "id": 304, "title": "Mauritania", "text": "/articles/mauritania.txt", "genre": "Geography & World Nations" },
  { "id": 305, "title": "Mauritius", "text": "/articles/mauritius.txt", "genre": "Geography & World Nations" },
  { "id": 306, "title": "Mergers and Acquisitions (M&A): Strategies for Growth and Expansion", "text": "/articles/mergers-and-acquisitions-(M&A)-strategies-for-growth-and-expansion.txt", "genre": "Business & Finance" },
  { "id": 307, "title": "Mexico", "text": "/articles/mexico.txt", "genre": "Geography & World Nations" },
  { "id": 308, "title": "Microbiology: The Invisible World of Microorganisms", "text": "/articles/microbiology-the-invisible-world-of-microorganisms.txt", "genre": "Science & Technology" },
  { "id": 309, "title": "Microbiology", "text": "/articles/microbiology.txt", "genre": "Science & Technology" },
  { "id": 310, "title": "Microbiome Research", "text": "/articles/microbiome-research.txt", "genre": "Science & Technology" },
  { "id": 311, "title": "Microbiomes", "text": "/articles/microbiomes.txt", "genre": "Science & Technology" },
  { "id": 312, "title": "Microgrids", "text": "/articles/microgrids.txt", "genre": "Science & Technology" },
  { "id": 313, "title": "Micronesia (Federated States of Micronesia)", "text": "/articles/micronesia-(federated-states-of-micronesia).txt", "genre": "Geography & World Nations" },
  { "id": 314, "title": "Moldova", "text": "/articles/moldova.txt", "genre": "Geography & World Nations" },
  { "id": 315, "title": "Mongolia", "text": "/articles/mongolia.txt", "genre": "Geography & World Nations" },
  { "id": 316, "title": "Montenegro", "text": "/articles/montenegro.txt", "genre": "Geography & World Nations" },
  { "id": 317, "title": "Morocco", "text": "/articles/morocco.txt", "genre": "Geography & World Nations" },
  { "id": 318, "title": "Mother Teresa: Saint of the Slums", "text": "/articles/mother-teresa-saint-of-the-slums.txt", "genre": "History" },
  { "id": 319, "title": "Mozambique", "text": "/articles/mozambique.txt", "genre": "Geography & World Nations" },
  { "id": 320, "title": "Myanmar (Burma)", "text": "/articles/myanmar-(burma).txt", "genre": "Geography & World Nations" },
  { "id": 321, "title": "Namibia", "text": "/articles/namibia.txt", "genre": "Geography & World Nations" },
  { "id": 322, "title": "Nanomedicine", "text": "/articles/nanomedicine.txt", "genre": "Science & Technology" },
  { "id": 323, "title": "Nanotechnology", "text": "/articles/nanotechnology.txt", "genre": "Science & Technology" },
  { "id": 324, "title": "Nationalism: Identity and Sovereignty", "text": "/articles/nationalism-identity-and-sovereignty.txt", "genre": "Political Science & Economics" },
  { "id": 325, "title": "Nationalism: The Politics of Identity and Sovereignty", "text": "/articles/nationalism-the-politics-of-identity-and-sovereignty.txt", "genre": "Political Science & Economics" },
  { "id": 326, "title": "Nauru", "text": "/articles/nauru.txt", "genre": "Geography & World Nations" },
  { "id": 327, "title": "Nelson Mandela: Champion of Freedom and Equality", "text": "/articles/nelson-mandela-champion-of-freedom-and-equality.txt", "genre": "History" },
  { "id": 328, "title": "Nelson Mandela: The Champion of South African Freedom", "text": "/articles/nelson-mandela-the-champion-of-south-african-freedom.txt", "genre": "History" },
  { "id": 329, "title": "Nepal", "text": "/articles/nepal.txt", "genre": "Geography & World Nations" },
  { "id": 330, "title": "Netherlands", "text": "/articles/netherlands.txt", "genre": "Geography & World Nations" },
  { "id": 331, "title": "Neuroaesthetics", "text": "/articles/neuroaesthetics.txt", "genre": "Psychology" },
  { "id": 332, "title": "Neuroarchitecture: The Impact of Design on the Brain", "text": "/articles/neuroarchitecture-the-impact-of-design-on-the-brain.txt", "genre": "Psychology" },
  { "id": 333, "title": "Neuroarchitecture", "text": "/articles/neuroarchitecture.txt", "genre": "Psychology" },
  { "id": 334, "title": "Neurofinance", "text": "/articles/neurofinance.txt", "genre": "Business & Finance" },
  { "id": 335, "title": "Neuromorphic Engineering", "text": "/articles/neuromorphic-engineering.txt", "genre": "Science & Technology" },
  { "id": 336, "title": "Neuroplasticity", "text": "/articles/neuroplasticity.txt", "genre": "Science & Technology" },
  { "id": 337, "title": "Neuroprosthetics", "text": "/articles/neuroprosthetics.txt", "genre": "Science & Technology" },
  { "id": 338, "title": "Neuroscience", "text": "/articles/neuroscience.txt", "genre": "Science & Technology" },
  { "id": 339, "title": "New Zealand", "text": "/articles/new-zealand.txt", "genre": "Geography & World Nations" },
  { "id": 340, "title": "Nicolaus Copernicus: The Father of Modern Astronomy", "text": "/articles/nicolaus-copernicus-the-father-of-modern-astronomy.txt", "genre": "Science & Mathematics" },
  { "id": 341, "title": "Niger", "text": "/articles/niger.txt", "genre": "Geography & World Nations" },
  { "id": 342, "title": "Nigeria", "text": "/articles/nigeria.txt", "genre": "Geography & World Nations" },
  { "id": 343, "title": "Nikola Tesla: The Visionary Inventor", "text": "/articles/nikola-tesla-the-visionary-inventor.txt", "genre": "Science & Technology" },
  { "id": 344, "title": "Nikolaus Otto: The Inventor of the Internal Combustion Engine", "text": "/articles/nikolaus-otto-the-inventor-of-the-internal-combustion-engine.txt", "genre": "Science & Technology" },
  { "id": 345, "title": "North Macedonia", "text": "/articles/north-macedonia.txt", "genre": "Geography & World Nations" },
  { "id": 346, "title": "Norway", "text": "/articles/norway.txt", "genre": "Geography & World Nations" },
  { "id": 347, "title": "Nuclear Disarmament and Non-Proliferation", "text": "/articles/nuclear-disarmament-and-non-proliferation.txt", "genre": "Political Science & International Relations" },
  { "id": 348, "title": "Number Theory: The Study of Integers", "text": "/articles/number-theory-the-study-of-integers.txt", "genre": "Science & Mathematics" },
  { "id": 349, "title": "Ocean Acidification", "text": "/articles/ocean-acidification.txt", "genre": "Environmental Science" },
  { "id": 350, "title": "Ocean Energy", "text": "/articles/ocean-energy.txt", "genre": "Environmental Science" },
{ "id": 351, "title": "Ocean Thermal Energy Conversion", "text": "/articles/ocean-thermal-energy-conversion.txt", "genre": "Environmental Science" },
  { "id": 352, "title": "Oman", "text": "/articles/oman.txt", "genre": "Geography & World Nations" },
  { "id": 353, "title": "Oprah Winfrey: The Media Mogul and Philanthropist", "text": "/articles/oprah-winfrey-the-media-mogul-and-philanthropist.txt", "genre": "Business & Finance" },
  { "id": 354, "title": "Pakistan", "text": "/articles/pakistan.txt", "genre": "Geography & World Nations" },
  { "id": 355, "title": "Palau", "text": "/articles/palau.txt", "genre": "Geography & World Nations" },
  { "id": 356, "title": "Papua New Guinea", "text": "/articles/papua-new-guinea.txt", "genre": "Geography & World Nations" },
  { "id": 357, "title": "Paraguay", "text": "/articles/paraguay.txt", "genre": "Geography & World Nations" },
  { "id": 358, "title": "Peru", "text": "/articles/peru.txt", "genre": "Geography & World Nations" },
  { "id": 359, "title": "Phenomenology: The Philosophy of Experience", "text": "/articles/phenomenology-the-philosophy-of-experience.txt", "genre": "Philosophy" },
  { "id": 360, "title": "Philippines", "text": "/articles/philippines.txt", "genre": "Geography & World Nations" },
  { "id": 361, "title": "Plant-Based Diets", "text": "/articles/plant-based-diets.txt", "genre": "Health & Wellness" },
  { "id": 362, "title": "Plate Tectonics: The Dynamic Earth", "text": "/articles/plate-tectonics-the-dynamic-earth.txt", "genre": "Science & Mathematics" },
  { "id": 363, "title": "Plato: The Philosopher of Ideals", "text": "/articles/plato-the-philosopher-of-ideals.txt", "genre": "Philosophy" },
  { "id": 364, "title": "Poland", "text": "/articles/poland.txt", "genre": "Geography & World Nations" },
  { "id": 365, "title": "Populism: The Politics of the People", "text": "/articles/populism-the-politics-of-the-people.txt", "genre": "Political Science & Economics" },
  { "id": 366, "title": "Populism: The Voice of the People", "text": "/articles/populism-the-voice-of-the-people.txt", "genre": "Political Science & Economics" },
  { "id": 367, "title": "Portugal", "text": "/articles/portugal.txt", "genre": "Geography & World Nations" },
  { "id": 368, "title": "Postmodernism: The Philosophy of Fragmentation and Plurality", "text": "/articles/postmodernism-the-philosophy-of-fragmentation-and-plurality.txt", "genre": "Philosophy" },
  { "id": 369, "title": "Practice Gratitude Daily", "text": "/articles/practice-gratitude-daily.txt", "genre": "Self-Help & Personal Development" },
  { "id": 370, "title": "Practice Self-Compassion", "text": "/articles/practice-self-compassion.txt", "genre": "Self-Help & Personal Development" },
  { "id": 371, "title": "Pragmatism: The Philosophy of Practical Consequences", "text": "/articles/pragmatism-the-philosophy-of-practical-consequences.txt", "genre": "Philosophy" },
  { "id": 372, "title": "Precision Agriculture", "text": "/articles/precision-agriculture.txt", "genre": "Environmental Science" },
  { "id": 373, "title": "Private Equity: Investing in Growth and Transformation", "text": "/articles/private-equity-investing-in-growth-and-transformation.txt", "genre": "Business & Finance" },
  { "id": 374, "title": "Probability: The Mathematics of Uncertainty", "text": "/articles/probability-the-mathematics-of-uncertainty.txt", "genre": "Science & Mathematics" },
  { "id": 375, "title": "Promote Economic Integration and Trade Partnerships", "text": "/articles/promote-economic-integration-and-trade-partnerships.txt", "genre": "Political Science & International Relations" },
  { "id": 376, "title": "Promote Environmental Sustainability", "text": "/articles/promote-environmental-sustainability.txt", "genre": "Environmental Science" },
  { "id": 377, "title": "Promote Multilateralism and Global Governance", "text": "/articles/promote-multilateralism-and-global-governance.txt", "genre": "Political Science & International Relations" },
  { "id": 378, "title": "Promote Planetary Protection and Stewardship", "text": "/articles/promote-planetary-protection-and-stewardship.txt", "genre": "Environmental Science" },
  { "id": 379, "title": "Promote Universal Scientific Collaboration", "text": "/articles/promote-universal-scientific-collaboration.txt", "genre": "Science & Technology" },
  { "id": 380, "title": "Prophet Muhammad: The Messenger of Islam", "text": "/articles/prophet-muhammad-the-messenger-of-islam.txt", "genre": "History" },
  { "id": 381, "title": "Public Transport Systems for Sustainable Mobility", "text": "/articles/public-transport-systems-for-sustainable-mobility.txt", "genre": "Environmental Science" },
  { "id": 382, "title": "Pursue the Search for Extraterrestrial Life", "text": "/articles/pursue-the-search-for-extraterrestrial-life.txt", "genre": "Science & Technology" },
  { "id": 383, "title": "Pythagoras: The Philosopher and Mathematician", "text": "/articles/pythagoras-the-philosopher-and-mathematician.txt", "genre": "Science & Mathematics" },
  { "id": 384, "title": "Qatar", "text": "/articles/qatar.txt", "genre": "Geography & World Nations" },
  { "id": 385, "title": "Quantum Biology", "text": "/articles/quantum-biology.txt", "genre": "Science & Technology" },
  { "id": 386, "title": "Quantum Computing", "text": "/articles/quantum-computing.txt", "genre": "Science & Technology" },
  { "id": 387, "title": "Quantum Cryptography", "text": "/articles/quantum-cryptography.txt", "genre": "Science & Technology" },
  { "id": 388, "title": "Quantum Mechanics", "text": "/articles/quantum-mechanics.txt", "genre": "Science & Technology" },
  { "id": 389, "title": "Quantum Sensors", "text": "/articles/quantum-sensors.txt", "genre": "Science & Technology" },
  { "id": 390, "title": "Regenerative Agriculture", "text": "/articles/regenerative-agriculture.txt", "genre": "Environmental Science" },
  { "id": 391, "title": "Relativity: The Fabric of Space and Time", "text": "/articles/relativity-the-fabric-of-space-and-time.txt", "genre": "Science & Mathematics" },
  { "id": 392, "title": "René Descartes: The Father of Modern Philosophy", "text": "/articles/rene-descartes-the-father-of-modern-philosophy.txt", "genre": "Philosophy" },
  { "id": 393, "title": "Renewable Agriculture", "text": "/articles/renewable-agriculture.txt", "genre": "Environmental Science" },
  { "id": 394, "title": "Renewable Energy Policy and Its Global Impact", "text": "/articles/renewable-energy-policy-and-its-global-impact.txt", "genre": "Environmental Science" },
  { "id": 395, "title": "Renewable Energy Policy", "text": "/articles/renewable-energy-policy.txt", "genre": "Environmental Science" },
  { "id": 396, "title": "Renewable Energy Storage: Key to a Sustainable Future", "text": "/articles/renewable-energy-storage-key-to-a-sustainable-future.txt", "genre": "Environmental Science" },
  { "id": 397, "title": "Renewable Energy Storage", "text": "/articles/renewable-energy-storage.txt", "genre": "Environmental Science" },
  { "id": 398, "title": "Renewable Energy", "text": "/articles/renewable-energy.txt", "genre": "Environmental Science" },
  { "id": 399, "title": "Renewable Resources", "text": "/articles/renewable-resources.txt", "genre": "Environmental Science" },
  { "id": 400, "title": "Richard Branson: The Maverick Entrepreneur", "text": "/articles/richard-branson-the-maverick-entrepreneur.txt", "genre": "Business & Finance" },
 { "id": 401, "title": "Richard Feynman: The Quantum Theorist", "text": "/articles/richard-feynman-the-quantum-theorist.txt", "genre": "Science & Technology" },
  { "id": 402, "title": "Robotics and the Future of Human Work", "text": "/articles/robotics-and-the-future-of-human-work.txt", "genre": "Science & Technology" },
  { "id": 403, "title": "Robotics", "text": "/articles/robotics.txt", "genre": "Science & Technology" },
  { "id": 404, "title": "Rohingya Crisis in Myanmar: Safe Return and Political Reconciliation", "text": "/articles/rohingya-crisis-in-myanmar-safe-return-and-political-reconciliation.txt", "genre": "Political Science & International Relations" },
  { "id": 405, "title": "Rollo May: The Existential Psychologist", "text": "/articles/rollo-may-the-existential-psychologist.txt", "genre": "Psychology" },
  { "id": 406, "title": "Romania", "text": "/articles/romania.txt", "genre": "Geography & World Nations" },
  { "id": 407, "title": "Rosa Parks: The Mother of the Civil Rights Movement", "text": "/articles/rosa-parks-the-mother-of-the-civil-rights-movement.txt", "genre": "History" },
  { "id": 408, "title": "Rosalind Franklin: The Unsung Hero of DNA", "text": "/articles/rosalind-franklin-the-unsung-hero-of-dna.txt", "genre": "Science & Technology" },
  { "id": 409, "title": "Rumi: The Mystic Poet of Sufism", "text": "/articles/rumi-the-mystic-poet-of-sufism.txt", "genre": "Arts & Humanities" },
  { "id": 410, "title": "Russia", "text": "/articles/russia.txt", "genre": "Geography & World Nations" },
  { "id": 411, "title": "Rwanda", "text": "/articles/rwanda.txt", "genre": "Geography & World Nations" },
  { "id": 412, "title": "Saint Kitts and Nevis", "text": "/articles/saint-kitts-and-nevis.txt", "genre": "Geography & World Nations" },
  { "id": 413, "title": "San Marino", "text": "/articles/san-marino.txt", "genre": "Geography & World Nations" },
  { "id": 414, "title": "São Tomé and Príncipe", "text": "/articles/sao-tome-and-principe.txt", "genre": "Geography & World Nations" },
  { "id": 415, "title": "Saudi Arabia", "text": "/articles/saudi-arabia.txt", "genre": "Geography & World Nations" },
  { "id": 416, "title": "Self-Healing Materials", "text": "/articles/self-healing-materials.txt", "genre": "Science & Technology" },
  { "id": 417, "title": "Senegal", "text": "/articles/senegal.txt", "genre": "Geography & World Nations" },
  { "id": 418, "title": "Serbia", "text": "/articles/serbia.txt", "genre": "Geography & World Nations" },
  { "id": 419, "title": "Set and Pursue Personal Goals", "text": "/articles/set-and-pursue-personal-goals.txt", "genre": "Self-Help & Personal Development" },
  { "id": 420, "title": "Set Theory: The Foundation of Modern Mathematics", "text": "/articles/set-theory-the-foundation-of-modern-mathematics.txt", "genre": "Science & Mathematics" },
  { "id": 421, "title": "Seychelles", "text": "/articles/seychelles.txt", "genre": "Geography & World Nations" },
  { "id": 422, "title": "Sheryl Sandberg: The Advocate for Women in Leadership", "text": "/articles/sheryl-sandberg-the-advocate-for-women-in-leadership.txt", "genre": "Business & Finance" },
  { "id": 423, "title": "Shinto: The Way of the Kami", "text": "/articles/shinto-the-way-of-the-kami.txt", "genre": "Religion & Spirituality" },
  { "id": 424, "title": "Sierra Leone", "text": "/articles/sierra-leone.txt", "genre": "Geography & World Nations" },
  { "id": 425, "title": "Sigmund Freud: The Father of Psychoanalysis", "text": "/articles/sigmund-freud-the-father-of-psychoanalysis.txt", "genre": "Psychology" },
  { "id": 426, "title": "Sikhism: The Faith of Unity and Service", "text": "/articles/sikhism-the-faith-of-unity-and-service.txt", "genre": "Religion & Spirituality" },
  { "id": 427, "title": "Simone de Beauvoir: The Philosopher of Feminism and Existentialism", "text": "/articles/simone-de-beauvoir-the-philosopher-of-feminism-and-existentialism.txt", "genre": "Philosophy" },
  { "id": 428, "title": "Singapore", "text": "/articles/singapore.txt", "genre": "Geography & World Nations" },
  { "id": 429, "title": "Circular Economy", "text": "/articles/circular-economy.txt", "genre": "Environmental Science" },
  { "id": 430, "title": "Slovenia", "text": "/articles/slovenia.txt", "genre": "Geography & World Nations" },
  { "id": 431, "title": "Smart Cities", "text": "/articles/smart-cities.txt", "genre": "Science & Technology" },
  { "id": 432, "title": "Smart Grids", "text": "/articles/smart-grids.txt", "genre": "Science & Technology" },
  { "id": 433, "title": "Smart Textiles", "text": "/articles/smart-textiles.txt", "genre": "Science & Technology" },
  { "id": 434, "title": "Social Media Algorithms", "text": "/articles/social-media-algorithms.txt", "genre": "Science & Technology" },
  { "id": 435, "title": "Social Robotics", "text": "/articles/social-robotics.txt", "genre": "Science & Technology" },
  { "id": 436, "title": "Socialism: The Pursuit of Economic and Social Equality", "text": "/articles/socialism-the-pursuit-of-economic-and-social-equality.txt", "genre": "Political Science & Economics" },
  { "id": 437, "title": "Socialism: The Pursuit of Economic Equality", "text": "/articles/socialism-the-pursuit-of-economic-equality.txt", "genre": "Political Science & Economics" },
  { "id": 438, "title": "Socrates: The Father of Western Philosophy", "text": "/articles/socrates-the-father-of-western-philosophy.txt", "genre": "Philosophy" },
  { "id": 439, "title": "Solar Water Desalination", "text": "/articles/solar-water-desalination.txt", "genre": "Environmental Science" },
  { "id": 440, "title": "Solomon Islands", "text": "/articles/solomon-islands.txt", "genre": "Geography & World Nations" },
  { "id": 441, "title": "Somalia", "text": "/articles/somalia.txt", "genre": "Geography & World Nations" },
  { "id": 442, "title": "South Africa", "text": "/articles/south-africa.txt", "genre": "Geography & World Nations" },
  { "id": 443, "title": "South China Sea Dispute: Multilateral Dialogue and Maritime Cooperation", "text": "/articles/south-china-sea-dispute-multilateral-dialogue-and-maritime-cooperation.txt", "genre": "Political Science & International Relations" },
  { "id": 444, "title": "South Korea", "text": "/articles/south-korea.txt", "genre": "Geography & World Nations" },
  { "id": 445, "title": "South Sudan", "text": "/articles/south-sudan.txt", "genre": "Geography & World Nations" },
{ "id": 446, "title": "Space Colonization", "text": "/articles/space-colonization.txt", "genre": "Science & Technology" },
  { "id": 447, "title": "Space Debris Mitigation", "text": "/articles/space-debris-mitigation.txt", "genre": "Science & Technology" },
  { "id": 448, "title": "Space Elevators", "text": "/articles/space-elevators.txt", "genre": "Science & Technology" },
  { "id": 449, "title": "Space Telescopes and Their Discoveries", "text": "/articles/space-telescopes-and-their-discoveries.txt", "genre": "Science & Technology" },
  { "id": 450, "title": "Space Tourism: Exploring the Final Frontier", "text": "/articles/space-tourism-exploring-the-final-frontier.txt", "genre": "Science & Technology" },
  { "id": 451, "title": "Space Tourism", "text": "/articles/space-tourism.txt", "genre": "Science & Technology" },
  { "id": 452, "title": "Space Weather", "text": "/articles/space-weather.txt", "genre": "Science & Technology" },
  { "id": 453, "title": "Spain", "text": "/articles/spain.txt", "genre": "Geography & World Nations" },
  { "id": 454, "title": "Sri Lanka", "text": "/articles/sri-lanka.txt", "genre": "Geography & World Nations" },
  { "id": 455, "title": "Srinivasa Ramanujan: The Self-Taught Mathematical Genius", "text": "/articles/srinivasa-ramanujan-the-self-taught-mathematical-genius.txt", "genre": "Science & Mathematics" },
  { "id": 456, "title": "Stephen Hawking: The Cosmologist and Theoretical Physicist", "text": "/articles/stephen-hawking-the-cosmologist-and-theoretical-physicist.txt", "genre": "Science & Technology" },
  { "id": 457, "title": "Steve Jobs: The Visionary Behind Apple", "text": "/articles/steve-jobs-the-visionary-behind-apple.txt", "genre": "Business & Finance" },
  { "id": 458, "title": "Stock Market: The Engine of Capital and Investment", "text": "/articles/stock-market-the-engine-of-capital-and-investment.txt", "genre": "Business & Finance" },
  { "id": 459, "title": "Stoicism: The Philosophy of Resilience", "text": "/articles/stoicism-the-philosophy-of-resilience.txt", "genre": "Philosophy" },
  { "id": 460, "title": "Structuralism: The Philosophy of Structures", "text": "/articles/structuralism-the-philosophy-of-structures.txt", "genre": "Philosophy" },
  { "id": 461, "title": "Support Community Engagement and Civic Participation", "text": "/articles/support-community-engagement-and-civic-participation.txt", "genre": "Self-Help & Personal Development" },
  { "id": 462, "title": "Suriname", "text": "/articles/suriname.txt", "genre": "Geography & World Nations" },
  { "id": 463, "title": "Sustainable Agriculture: Balancing Food Production and Environmental Health", "text": "/articles/sustainable-agriculture-balancing-food-production-and-environmental-health.txt", "genre": "Environmental Science" },
  { "id": 464, "title": "Sustainable Agriculture", "text": "/articles/sustainable-agriculture.txt", "genre": "Environmental Science" },
  { "id": 465, "title": "Sustainable Aquaculture", "text": "/articles/sustainable-aquaculture.txt", "genre": "Environmental Science" },
  { "id": 466, "title": "Sustainable Investing: Aligning Profits with Purpose", "text": "/articles/sustainable-investing-aligning-profits-with-purpose.txt", "genre": "Business & Finance" },
  { "id": 467, "title": "Sustainable Resource Management and Conflict Prevention", "text": "/articles/sustainable-resource-management-and-conflict-prevention.txt", "genre": "Environmental Science" },
  { "id": 468, "title": "Sustainable Tourism and Environmental Conservation", "text": "/articles/sustainable-tourism-and-environmental-conservation.txt", "genre": "Environmental Science" },
  { "id": 469, "title": "Sustainable Tourism", "text": "/articles/sustainable-tourism.txt", "genre": "Environmental Science" },
  { "id": 470, "title": "Swami Vivekananda: The Spiritual Ambassador of Hinduism", "text": "/articles/swami-vivekananda-the-spiritual-ambassador-of-hinduism.txt", "genre": "Religion & Spirituality" },
  { "id": 471, "title": "Swarm Robotics", "text": "/articles/swarm-robotics.txt", "genre": "Science & Technology" },
  { "id": 472, "title": "Sweden", "text": "/articles/sweden.txt", "genre": "Geography & World Nations" },
  { "id": 473, "title": "Switzerland", "text": "/articles/switzerland.txt", "genre": "Geography & World Nations" },
  { "id": 474, "title": "Synthetic Biology", "text": "/articles/synthetic-biology.txt", "genre": "Science & Technology" },
  { "id": 475, "title": "Syria", "text": "/articles/syria.txt", "genre": "Geography & World Nations" },
  { "id": 476, "title": "Syrian Civil War: A Comprehensive Peace Process", "text": "/articles/syrian-civil-war-a-comprehensive-peace-process.txt", "genre": "Political Science & International Relations" },
  { "id": 477, "title": "Tajikistan", "text": "/articles/tajikistan.txt", "genre": "Geography & World Nations" },
  { "id": 478, "title": "Tanzania", "text": "/articles/tanzania.txt", "genre": "Geography & World Nations" },
  { "id": 479, "title": "Taoism: Harmony with the Tao", "text": "/articles/taoism-harmony-with-the-tao.txt", "genre": "Religion & Spirituality" },
  { "id": 480, "title": "Taoism: The Philosophy of Harmony", "text": "/articles/taoism-the-philosophy-of-harmony.txt", "genre": "Philosophy" },
  { "id": 481, "title": "Telemedicine for Accessible Healthcare", "text": "/articles/telemedicine-for-accessible-healthcare.txt", "genre": "Health & Wellness" },
  { "id": 482, "title": "Thailand", "text": "/articles/thailand.txt", "genre": "Geography & World Nations" },
  { "id": 483, "title": "The Amazon Rainforest", "text": "/articles/the-amazon-rainforest.txt", "genre": "Environmental Science" },
  { "id": 484, "title": "The Art of the Baroque Period", "text": "/articles/the-art-of-baroque-period.txt", "genre": "Arts & Humanities" },
  { "id": 485, "title": "The Big Bang Theory: The Origin of the Universe", "text": "/articles/the-big-bang-theory-the-origin-of-the-universe.txt", "genre": "Science & Technology" },
  { "id": 486, "title": "The Chemistry of Cooking", "text": "/articles/the-chemistry-of-cooking.txt", "genre": "Science & Technology" },
  { "id": 487, "title": "The Cold War: A Geopolitical Rivalry", "text": "/articles/the-cold-war-a-geopolitical-rivalry.txt", "genre": "History" },
  { "id": 488, "title": "The Cold War", "text": "/articles/the-cold-war.txt", "genre": "History" },
  { "id": 489, "title": "The Concept of Collective Consciousness", "text": "/articles/the-concept-of-collective-consciousness.txt", "genre": "Philosophy" },
  { "id": 490, "title": "The Crusades", "text": "/articles/the-crusades.txt", "genre": "History" },
  { "id": 491, "title": "The Cultural Significance of Folk Music", "text": "/articles/the-cultural-significance-of-folk-music.txt", "genre": "Arts & Humanities" },
  { "id": 492, "title": "The Development of Renewable Energy Technologies", "text": "/articles/the-development-of-renewable-energy-technologies.txt", "genre": "Environmental Science" },
  { "id": 493, "title": "The Dynamics of Predator-Prey Relationships", "text": "/articles/the-dynamics-of-predator-prey-relationships.txt", "genre": "Science & Technology" },
  { "id": 494, "title": "The Economics of Universal Basic Income (UBI)", "text": "/articles/the-economics-of-universal-basic-income-(ubi).txt", "genre": "Political Science & Economics" },
  { "id": 495, "title": "The Ethics of Animal Testing", "text": "/articles/the-ethics-of-animal-testing.txt", "genre": "Ethics & Philosophy" },
  { "id": 496, "title": "The Ethics of Artificial Life", "text": "/articles/the-ethics-of-artificial-life.txt", "genre": "Ethics & Philosophy" },
  { "id": 497, "title": "The Ethics of Genetic Modification", "text": "/articles/the-ethics-of-genetic-modification.txt", "genre": "Ethics & Philosophy" },
  { "id": 498, "title": "The Evolution of Democracy", "text": "/articles/the-evolution-of-democracy.txt", "genre": "Political Science & Economics" },
  { "id": 499, "title": "The Evolution of Early Human Societies", "text": "/articles/the-evolution-of-early-human-societies.txt", "genre": "History" },
  { "id": 500, "title": "The Evolution of Economic Systems", "text": "/articles/the-evolution-of-economic-systems.txt", "genre": "Political Science & Economics" },
 { "id": 501, "title": "The Evolution of Educational Systems", "text": "/articles/the-evolution-of-educational-systems.txt", "genre": "Education & Learning" },
  { "id": 502, "title": "The Evolution of Fashion and Clothing", "text": "/articles/the-evolution-of-fashion-and-clothing.txt", "genre": "Arts & Humanities" },
  { "id": 503, "title": "The Evolution of Language and Communication", "text": "/articles/the-evolution-of-language-and-communication.txt", "genre": "Arts & Humanities" },
  { "id": 504, "title": "The Evolution of Linguistics", "text": "/articles/the-evolution-of-linguistics.txt", "genre": "Arts & Humanities" },
  { "id": 505, "title": "The Evolution of Medicine", "text": "/articles/the-evolution-of-medicine.txt", "genre": "Health & Wellness" },
  { "id": 506, "title": "The Evolution of Music Recording Technology", "text": "/articles/the-evolution-of-music-recording-technology.txt", "genre": "Arts & Humanities" },
  { "id": 507, "title": "The Evolution of Renewable Agriculture Practices", "text": "/articles/the-evolution-of-renewable-agriculture-practices.txt", "genre": "Environmental Science" },
  { "id": 508, "title": "The Evolution of the English Language", "text": "/articles/the-evolution-of-the-english-language.txt", "genre": "Arts & Humanities" },
  { "id": 509, "title": "The Evolution of the Internet", "text": "/articles/the-evolution-of-the-internet.txt", "genre": "Science & Technology" },
  { "id": 510, "title": "The Evolution of Video Game Design", "text": "/articles/the-evolution-of-video-game-design.txt", "genre": "Arts & Humanities" },
  { "id": 511, "title": "The Exploration of the Deep Ocean", "text": "/articles/the-exploration-of-the-deep-ocean.txt", "genre": "Science & Technology" },
  { "id": 512, "title": "The French Revolution", "text": "/articles/the-french-revolution.txt", "genre": "History" },
  { "id": 513, "title": "The Future of Artificial Intelligence in Space Exploration", "text": "/articles/the-future-of-artificial-intelligence-in-space-exploration.txt", "genre": "Science & Technology" },
  { "id": 514, "title": "The Future of Education: Personalized Learning and EdTech", "text": "/articles/the-future-of-education-personalized-learning-and-edtech.txt", "genre": "Education & Learning" },
  { "id": 515, "title": "The Future of Food Security", "text": "/articles/the-future-of-food-security.txt", "genre": "Environmental Science" },
  { "id": 516, "title": "The Future of Remote Work", "text": "/articles/the-future-of-remote-work.txt", "genre": "Business & Finance" },
  { "id": 517, "title": "The Global Water Crisis", "text": "/articles/the-global-water-crisis.txt", "genre": "Environmental Science" },
  { "id": 518, "title": "The Great Barrier Reef", "text": "/articles/the-great-barrier-reef.txt", "genre": "Environmental Science" },
  { "id": 519, "title": "The Great Depression", "text": "/articles/the-great-depression.txt", "genre": "History" },
  { "id": 520, "title": "The Green Revolution: Agricultural Transformation", "text": "/articles/the-green-revolution-agricultural-transformation.txt", "genre": "Environmental Science" },
  { "id": 521, "title": "The Green Revolution", "text": "/articles/the-green-revolution.txt", "genre": "Environmental Science" },
  { "id": 522, "title": "The History and Development of Jazz Music", "text": "/articles/the-history-and-development-of-jazz-music.txt", "genre": "Arts & Humanities" },
  { "id": 523, "title": "The History and Evolution of Architecture", "text": "/articles/the-history-and-evolution-of-architecture.txt", "genre": "Arts & Humanities" },
  { "id": 524, "title": "The History and Evolution of Computing", "text": "/articles/the-history-and-evolution-of-computing.txt", "genre": "Science & Technology" },
  { "id": 525, "title": "The History and Evolution of Dance", "text": "/articles/the-history-and-evolution-of-dance.txt", "genre": "Arts & Humanities" },
  { "id": 526, "title": "The History and Impact of Cinema", "text": "/articles/the-history-and-impact-of-cinema.txt", "genre": "Arts & Humanities" },
  { "id": 527, "title": "The History and Impact of the Industrial Revolution", "text": "/articles/the-history-and-impact-of-the-industrial-revolution.txt", "genre": "History" },
  { "id": 528, "title": "The History and Significance of the Silk Road", "text": "/articles/the-history-and-significance-of-the-silk-road.txt", "genre": "History" },
  { "id": 529, "title": "The History of Comic Books", "text": "/articles/the-history-of-comic-books.txt", "genre": "Arts & Humanities" },
  { "id": 530, "title": "The History of Cryptocurrencies", "text": "/articles/the-history-of-cryptocurrencies.txt", "genre": "Business & Finance" },
  { "id": 531, "title": "The History of Cryptography", "text": "/articles/the-history-of-cryptography.txt", "genre": "Science & Technology" },
  { "id": 532, "title": "The History of Espionage", "text": "/articles/the-history-of-espionage.txt", "genre": "History" },
  { "id": 533, "title": "The History of Human Rights", "text": "/articles/the-history-of-human-rights.txt", "genre": "History" },
  { "id": 534, "title": "The History of Public Health", "text": "/articles/the-history-of-public-health.txt", "genre": "Health & Wellness" },
  { "id": 535, "title": "The History of Quantum Physics", "text": "/articles/the-history-of-quantum-physics.txt", "genre": "Science & Technology" },
  { "id": 536, "title": "The History of Space Colonization Concepts", "text": "/articles/the-history-of-space-colonization-concepts.txt", "genre": "Science & Technology" },
  { "id": 537, "title": "The History of Space Exploration", "text": "/articles/the-history-of-space-exploration.txt", "genre": "Science & Technology" },
  { "id": 538, "title": "The History of Space Stations", "text": "/articles/the-history-of-space-stations.txt", "genre": "Science & Technology" },
  { "id": 539, "title": "The History of Space Telescopes", "text": "/articles/the-history-of-space-telescopes.txt", "genre": "Science & Technology" },
  { "id": 540, "title": "The History of the Internet", "text": "/articles/the-history-of-the-internet.txt", "genre": "Science & Technology" },
  { "id": 541, "title": "The History of the Olympics Games", "text": "/articles/the-history-of-the-olympics-games.txt", "genre": "History" },
  { "id": 542, "title": "The History of the Printing Press", "text": "/articles/the-history-of-the-printing-press.txt", "genre": "Arts & Humanities" },
  { "id": 543, "title": "The History of Vaccination", "text": "/articles/the-history-of-vaccination.txt", "genre": "Health & Wellness" },
  { "id": 544, "title": "The Human Brain", "text": "/articles/the-human-brain.txt", "genre": "Health & Wellness" },
  { "id": 545, "title": "The Human Genome Project", "text": "/articles/the-human-genome-project.txt", "genre": "Science & Technology" },
  { "id": 546, "title": "The Human Immune System", "text": "/articles/the-human-immune-system.txt", "genre": "Health & Wellness" },
  { "id": 547, "title": "The Impact of Artificial Intelligence on Art", "text": "/articles/the-impact-of-artificial-intelligence-on-art.txt", "genre": "Science & Technology" },
  { "id": 548, "title": "The Impact of Artificial Intelligence on Education", "text": "/articles/the-impact-of-artificial-intelligence-on-education.txt", "genre": "Education & Learning" },
  { "id": 549, "title": "The Impact of Artificial Intelligence on Employment", "text": "/articles/the-impact-of-artificial-intelligence-on-employment.txt", "genre": "Business & Finance" },
  { "id": 550, "title": "The Impact of Big Data on Healthcare", "text": "/articles/the-impact-of-big-data-on-healthcare.txt", "genre": "Health & Wellness" },
{ "id": 551, "title": "The Impact of Global Trade on Developing Economies", "text": "/articles/the-impact-of-global-trade-on-developing-economies.txt", "genre": "Business & Finance" },
  { "id": 552, "title": "The Impact of Globalization on Culture", "text": "/articles/the-impact-of-globalization-on-culture.txt", "genre": "Arts & Humanities" },
  { "id": 553, "title": "The Impact of Media on Public Perception", "text": "/articles/the-impact-of-media-on-public-perception.txt", "genre": "Arts & Humanities" },
  { "id": 554, "title": "The Impact of Social Media on Mental Health", "text": "/articles/the-impact-of-social-media-on-mental-health.txt", "genre": "Health & Wellness" },
  { "id": 555, "title": "The Impact of Social Media on Politics", "text": "/articles/the-impact-of-social-media-on-politics.txt", "genre": "Political Science & International Relations" },
  { "id": 556, "title": "The Impact of Social Media on Society", "text": "/articles/the-impact-of-social-media-on-society.txt", "genre": "Social & Political" },
  { "id": 557, "title": "The Impact of Space Weather on Earth", "text": "/articles/the-impact-of-space-weather-on-earth.txt", "genre": "Science & Technology" },
  { "id": 558, "title": "The Industrial Revolution", "text": "/articles/the-industrial-revolution.txt", "genre": "History" },
  { "id": 559, "title": "The Influence of Ancient Greek Philosophy on Modern Thought", "text": "/articles/the-influence-of-ancient-greek-philosophy-on-modern-thought.txt", "genre": "Philosophy" },
  { "id": 560, "title": "The Influence of Renaissance Humanism", "text": "/articles/the-influence-of-renaissance-humanism.txt", "genre": "Philosophy" },
  { "id": 561, "title": "The Internet of Things (IoT) and Smart Cities", "text": "/articles/the-internet-of-things-IoT-and-smart-cities.txt", "genre": "Science & Technology" },
  { "id": 562, "title": "The Internet of Things", "text": "/articles/the-internet-of-things.txt", "genre": "Science & Technology" },
  { "id": 563, "title": "The Mathematics of Infinity", "text": "/articles/the-mathematics-of-infinity.txt", "genre": "Science & Mathematics" },
  { "id": 564, "title": "The Neuroscience of Creativity", "text": "/articles/the-neuroscience-of-creativity.txt", "genre": "Psychology" },
  { "id": 565, "title": "The Neuroscience of Sleep", "text": "/articles/the-neuroscience-of-sleep.txt", "genre": "Psychology" },
  { "id": 566, "title": "The Ottoman Empire", "text": "/articles/the-ottoman-empire.txt", "genre": "History" },
  { "id": 567, "title": "The Philosophy of Existentialism", "text": "/articles/the-philosophy-of-existentialism.txt", "genre": "Philosophy" },
  { "id": 568, "title": "The Philosophy of Language", "text": "/articles/the-philosophy-of-language.txt", "genre": "Philosophy" },
  { "id": 569, "title": "The Philosophy of Minimalism", "text": "/articles/the-philosophy-of-minimalism.txt", "genre": "Philosophy" },
  { "id": 570, "title": "The Philosophy of Science: Understanding the Scientific Method", "text": "/articles/the-philosophy-of-science-understanding-the-scientific-method.txt", "genre": "Philosophy" },
  { "id": 571, "title": "The Philosophy of Science", "text": "/articles/the-philosophy-of-science.txt", "genre": "Philosophy" },
  { "id": 572, "title": "The Philosophy of Time", "text": "/articles/the-philosophy-of-time.txt", "genre": "Philosophy" },
  { "id": 573, "title": "The Physics of Quantum Entanglement", "text": "/articles/the-physics-of-quantum-entanglement.txt", "genre": "Science & Mathematics" },
  { "id": 574, "title": "The Physics of Sound", "text": "/articles/the-physics-of-sound.txt", "genre": "Science & Mathematics" },
  { "id": 575, "title": "The Potential of Vertical Farming", "text": "/articles/the-potential-of-vertical-farming.txt", "genre": "Environmental Science" },
  { "id": 576, "title": "The Principles of Democracy", "text": "/articles/the-principles-of-democracy.txt", "genre": "Political Science & International Relations" },
  { "id": 577, "title": "The Psychology of Color", "text": "/articles/the-psychology-of-color.txt", "genre": "Psychology" },
  { "id": 578, "title": "The Psychology of Creativity", "text": "/articles/the-psychology-of-creativity.txt", "genre": "Psychology" },
  { "id": 579, "title": "The Psychology of Forgiveness", "text": "/articles/the-psychology-of-forgiveness.txt", "genre": "Psychology" },
  { "id": 580, "title": "The Psychology of Humor", "text": "/articles/the-psychology-of-humor.txt", "genre": "Psychology" },
  { "id": 581, "title": "The Psychology of Learning", "text": "/articles/the-psychology-of-learning.txt", "genre": "Psychology" },
  { "id": 582, "title": "The Psychology of Memory", "text": "/articles/the-psychology-of-memory.txt", "genre": "Psychology" },
  { "id": 583, "title": "The Psychology of Motivation", "text": "/articles/the-psychology-of-motivation.txt", "genre": "Psychology" },
  { "id": 584, "title": "The Psychology of Procrastination", "text": "/articles/the-psychology-of-procrastination.txt", "genre": "Psychology" },
  { "id": 585, "title": "The Renaissance in Art", "text": "/articles/the-renaissance-in-art.txt", "genre": "Arts & Humanities" },
  { "id": 586, "title": "The Renaissance in Science", "text": "/articles/the-renaissance-in-science.txt", "genre": "History" },
  { "id": 587, "title": "The Renaissance", "text": "/articles/the-renaissance.txt", "genre": "History" },
  { "id": 588, "title": "The Renewable Energy Transition", "text": "/articles/the-renewable-energy-transition.txt", "genre": "Environmental Science" },
  { "id": 589, "title": "The Rise of E-Commerce", "text": "/articles/the-rise-of-e-commerce.txt", "genre": "Business & Finance" },
  { "id": 590, "title": "The Rise of Eco-Cities", "text": "/articles/the-rise-of-eco-cities.txt", "genre": "Environmental Science" },
  { "id": 591, "title": "The Role of Bees in Agriculture", "text": "/articles/the-role-of-bees-in-agriculture.txt", "genre": "Environmental Science" },
  { "id": 592, "title": "The Role of Education in Society", "text": "/articles/the-role-of-education-in-society.txt", "genre": "Education & Learning" },
  { "id": 593, "title": "The Role of Forensic Science in Criminal Justice", "text": "/articles/the-role-of-forensic-science-in-criminal-justice.txt", "genre": "Science & Technology" },
  { "id": 594, "title": "The Role of Microorganisms in Human Health", "text": "/articles/the-role-of-microorganisms-in-human-health.txt", "genre": "Health & Wellness" },
  { "id": 595, "title": "The Role of Microorganisms in the Environment", "text": "/articles/the-role-of-microorganisms-in-the-environment.txt", "genre": "Environmental Science" },
  { "id": 596, "title": "The Role of Music in Human Culture", "text": "/articles/the-role-of-music-in-human-culture.txt", "genre": "Arts & Humanities" },
  { "id": 597, "title": "The Role of Music Therapy in Healing", "text": "/articles/the-role-of-music-therapy-in-healing.txt", "genre": "Health & Wellness" },
  { "id": 598, "title": "The Role of Philosophy in Science", "text": "/articles/the-role-of-philosophy-in-science.txt", "genre": "Philosophy" },
  { "id": 599, "title": "The Role of Public Libraries in Modern Society", "text": "/articles/the-role-of-public-libraries-in-modern-society.txt", "genre": "Education & Learning" },
  { "id": 600, "title": "The Role of Social Entrepreneurship", "text": "/articles/the-role-of-social-entrepreneurship.txt", "genre": "Business & Finance" },
 { "id": 601, "title": "The Role of Sports in Society", "text": "/articles/the-role-of-sports-in-society.txt", "genre": "Social & Political" },
  { "id": 602, "title": "The Role of Women in Ancient Civilizations", "text": "/articles/the-role-of-women-in-ancient-civilizations.txt", "genre": "History" },
  { "id": 603, "title": "The Role of Women in Science", "text": "/articles/the-role-of-women-in-science.txt", "genre": "Science & Technology" },
  { "id": 604, "title": "The Science Behind Food Preservation", "text": "/articles/the-science-behind-food-preservation.txt", "genre": "Science & Technology" },
  { "id": 605, "title": "The Science of Climate Change", "text": "/articles/the-science-of-climate-change.txt", "genre": "Environmental Science" },
  { "id": 606, "title": "The Science of Fractals in Nature", "text": "/articles/the-science-of-fractals-in-nature.txt", "genre": "Science & Mathematics" },
  { "id": 607, "title": "The Science of Habit Formation", "text": "/articles/the-science-of-habit-formation.txt", "genre": "Psychology" },
  { "id": 608, "title": "The Science of Happiness: What Makes Us Happy?", "text": "/articles/the-science-of-happiness-what-makes-us-happy.txt", "genre": "Psychology" },
  { "id": 609, "title": "The Science of Happiness", "text": "/articles/the-science-of-happiness.txt", "genre": "Psychology" },
  { "id": 610, "title": "The Science of Holography", "text": "/articles/the-science-of-holography.txt", "genre": "Science & Technology" },
  { "id": 611, "title": "The Science of Hydrology", "text": "/articles/the-science-of-hydrology.txt", "genre": "Science & Technology" },
  { "id": 612, "title": "The Science of Memory and Forgetting", "text": "/articles/the-science-of-memory-and-forgetting.txt", "genre": "Psychology" },
  { "id": 613, "title": "The Science of Ocean Currents", "text": "/articles/the-science-of-ocean-currents.txt", "genre": "Science & Technology" },
  { "id": 614, "title": "The Science of Sleep and Dreams", "text": "/articles/the-science-of-sleep-and-dreams.txt", "genre": "Psychology" },
  { "id": 615, "title": "The Science of Sleep", "text": "/articles/the-science-of-sleep.txt", "genre": "Psychology" },
  { "id": 616, "title": "The Significance of Rituals in Human Culture", "text": "/articles/the-significance-of-rituals-in-human-culture.txt", "genre": "Arts & Humanities" },
  { "id": 617, "title": "The Silk Road", "text": "/articles/the-silk-road.txt", "genre": "History" },
  { "id": 618, "title": "The Sociology of Fashion", "text": "/articles/the-sociology-of-fashion.txt", "genre": "Arts & Humanities" },
  { "id": 619, "title": "The Space Economy", "text": "/articles/the-space-economy.txt", "genre": "Business & Finance" },
  { "id": 620, "title": "The Space Race", "text": "/articles/the-space-race.txt", "genre": "History" },
  { "id": 621, "title": "The Space Shuttle Program", "text": "/articles/the-space-shuttle-program.txt", "genre": "Science & Technology" },
  { "id": 622, "title": "The Theory of Evolution: Understanding Life's Diversity", "text": "/articles/the-theory-of-evolution-understanding-lifes-diversity.txt", "genre": "Science & Mathematics" },
  { "id": 623, "title": "The Theory of Evolution", "text": "/articles/the-theory-of-evolution.txt", "genre": "Science & Mathematics" },
  { "id": 624, "title": "The Theory of Relativity", "text": "/articles/the-theory-of-relativity.txt", "genre": "Science & Mathematics" },
  { "id": 625, "title": "The United Nations", "text": "/articles/the-united-nations.txt", "genre": "Political Science & International Relations" },
  { "id": 626, "title": "Timor-Leste (East Timor)", "text": "/articles/timor-leste-(east-timor).txt", "genre": "Geography & World Nations" },
  { "id": 627, "title": "Togo", "text": "/articles/togo.txt", "genre": "Geography & World Nations" },
  { "id": 628, "title": "Tonga", "text": "/articles/tonga.txt", "genre": "Geography & World Nations" },
  { "id": 629, "title": "Topology: The Mathematics of Shapes and Spaces", "text": "/articles/topology-the-mathematics-of-shapes-and-spaces.txt", "genre": "Science & Mathematics" },
  { "id": 630, "title": "Topology: The Study of Spatial Properties", "text": "/articles/topology-the-study-of-spatial-properties.txt", "genre": "Science & Mathematics" },
  { "id": 631, "title": "Totalitarianism: The Absolute Control of the State", "text": "/articles/totalitarianism-the-absolute-control-of-the-state.txt", "genre": "Political Science & International Relations" },
  { "id": 632, "title": "Transcendentalism: The Philosophy of the Spirit", "text": "/articles/transcendentalism-the-philosophy-of-the-spirit.txt", "genre": "Philosophy" },
  { "id": 633, "title": "Trinidad and Tobago", "text": "/articles/trinidad-and-tobago.txt", "genre": "Geography & World Nations" },
  { "id": 634, "title": "Tunisia", "text": "/articles/tunisia.txt", "genre": "Geography & World Nations" },
  { "id": 635, "title": "Turkey", "text": "/articles/turkey.txt", "genre": "Geography & World Nations" },
  { "id": 636, "title": "Tuvalu", "text": "/articles/tuvalu.txt", "genre": "Geography & World Nations" },
  { "id": 637, "title": "Ukraine-Russia Conflict: Diplomatic Negotiations and Security Guarantees", "text": "/articles/ukraine-russia-conflict-diplomatic-negotiations-and-security-guarantees.txt", "genre": "Political Science & International Relations" },
  { "id": 638, "title": "Ukraine", "text": "/articles/ukraine.txt", "genre": "Geography & World Nations" },
  { "id": 639, "title": "Universal Access to Clean Water and Sanitation", "text": "/articles/universal-access-to-clean-water-and-sanitation.txt", "genre": "Environmental Science" },
  { "id": 640, "title": "Urban Farming: Rethinking Food Production in Cities", "text": "/articles/urban-farming-rethinking-food-production-in-cities.txt", "genre": "Environmental Science" },
  { "id": 641, "title": "Urban Farming", "text": "/articles/urban-farming.txt", "genre": "Environmental Science" },
  { "id": 642, "title": "Urban Planning and the Future of Cities", "text": "/articles/urban-planning-and-the-future-of-cities.txt", "genre": "Environmental Science" },
  { "id": 643, "title": "Uruguay", "text": "/articles/uruguay.txt", "genre": "Geography & World Nations" },
  { "id": 644, "title": "Utilitarianism: The Philosophy of Utility", "text": "/articles/utilitarianism-the-philosophy-of-utility.txt", "genre": "Philosophy" },
  { "id": 645, "title": "Uzbekistan", "text": "/articles/uzbekistan.txt", "genre": "Geography & World Nations" },
  { "id": 646, "title": "Vanuatu", "text": "/articles/vanuatu.txt", "genre": "Geography & World Nations" },
  { "id": 647, "title": "Vatican City", "text": "/articles/vatican-city.txt", "genre": "Geography & World Nations" },
  { "id": 648, "title": "Venezuela", "text": "/articles/venezuela.txt", "genre": "Geography & World Nations" },
  { "id": 649, "title": "Venture Capital: Fueling Innovation and Startups", "text": "/articles/venture-capital-fueling-innovation-and-startups.txt", "genre": "Business & Finance" },
  { "id": 650, "title": "Vertical Farming for Sustainable Food Production", "text": "/articles/vertical-farming-for-sustainable-food-production.txt", "genre": "Environmental Science" },
  { "id": 651, "title": "Vietnam", "text": "/articles/vietnam.txt", "genre": "Geography & World Nations" },
  { "id": 652, "title": "Viktor Frankl: The Founder of Logotherapy", "text": "/articles/viktor-frankl-the-founder-of-logotherapy.txt", "genre": "Psychology" },
  { "id": 653, "title": "Virtual Reality and Human Experience", "text": "/articles/virtual-reality-and-human-experience.txt", "genre": "Science & Technology" },
  { "id": 654, "title": "Virtual Reality", "text": "/articles/virtual-reality.txt", "genre": "Science & Technology" },
  { "id": 655, "title": "Volunteer and Give Back", "text": "/articles/volunteer-and-give-back.txt", "genre": "Self-Help & Personal Development" },
  { "id": 656, "title": "Wangari Maathai: Environmentalist and Nobel Laureate", "text": "/articles/wangari-maathai-environmentalist-and-nobel-laureate.txt", "genre": "Environmental Science" },
  { "id": 657, "title": "Warren Buffett: The Oracle of Omaha", "text": "/articles/warren-buffett-the-oracle-of-omaha.txt", "genre": "Business & Finance" },
  { "id": 658, "title": "Wearable Technology and Personal Health", "text": "/articles/wearable-technology-and-personal-health.txt", "genre": "Health & Wellness" },
  { "id": 659, "title": "Wearable Technology", "text": "/articles/wearable-technology.txt", "genre": "Science & Technology" },
  { "id": 660, "title": "Winston Churchill: The Wartime Leader of Britain", "text": "/articles/winston-churchill-the-wartime-leader-of-britain.txt", "genre": "History" },
  { "id": 661, "title": "Yemen Civil War: Humanitarian Ceasefire and Peace Talks", "text": "/articles/yemen-civil-war-humanitarian-ceasefire-and-peace-talks.txt", "genre": "Political Science & International Relations" },
  { "id": 662, "title": "Yemen", "text": "/articles/yemen.txt", "genre": "Geography & World Nations" },
  { "id": 663, "title": "Zambia", "text": "/articles/zambia.txt", "genre": "Geography & World Nations" },
  { "id": 664, "title": "Zimbabwe", "text": "/articles/zimbabwe.txt", "genre": "Geography & World Nations" },
  { "id": 665, "title": "Zoroaster (Zarathustra): The Prophet of Zoroastrianism", "text": "/articles/zoroaster-zarathustra-the-prophet-of-zoroastrianism.txt", "genre": "Religion & Spirituality" },
  { "id": 666, "title": "Zoroastrianism: The Religion of Fire and Light", "text": "/articles/zoroastrianism-the-religion-of-fire-and-light.txt", "genre": "Religion & Spirituality" }
      ],
      searchQuery: '',
      genres: [
        'History', 'Philosophy', 'Science & Technology', 'Psychology', 'Political Science & Economics',
        'Geography & World Nations', 'Business & Finance', 'Environmental Science', 'Science & Mathematics',
        'Education & Learning', 'Political Science & International Relations', 'Ethics & Philosophy',
        'Self-Help & Personal Development', 'Health & Wellness', 'Arts & Humanities'
      ],
      selectedGenre: null,
    };
  },
  computed: {
    filteredArticles() {
      const query = this.searchQuery.toLowerCase();
      return this.articles.filter((article) =>
        article.title.toLowerCase().includes(query) &&
        (!this.selectedGenre || article.genre === this.selectedGenre)
      );
    },
  },
  methods: {
    saveSearchQuery() {
      localStorage.setItem('articleSearchQuery', this.searchQuery);
    },
    getSearchQuery() {
      return localStorage.getItem('articleSearchQuery') || '';
    },
    saveScrollPosition() {
      localStorage.setItem('scrollPosition_ArticlesPage', this.$refs.content.scrollTop);
    },
    goBackToResources() {
      this.$router.push('/resources');
    },
    filterByGenre(genre) {
      this.selectedGenre = genre;
    },
  },
  mounted() {
    this.searchQuery = this.getSearchQuery();
    this.$nextTick(() => {
      const position = localStorage.getItem('scrollPosition_ArticlesPage');
      this.$refs.content.scrollTop = position ? parseInt(position, 10) : 0;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    this.saveSearchQuery();
    next();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.genres-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.genre-button {
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: white;
  cursor: pointer;
}

.articles-list {
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.article-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

input[type='text'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}
</style>
