<template>
  <div class="header">
    <div class="left">
      <button v-if="showBackButton" @click="emitBack" class="back-button">
        {{ isBooksPage || isArticlesPage ? "Get More Resources" : backText }}
      </button>
    </div>
    <div class="center">
      <router-link to="/topics">
        <img src="/profile-picture.jpg" class="logo" alt="Logo" />
      </router-link>
    </div>
    <div class="right">
      <button v-if="!isLoginPage" @click="toggleMenu" class="menu-button">Menu</button>
    </div>

    <!-- Menu for HomePage.vue: Only 'About Resuvonia' and 'Logout' -->
    <div v-if="showMenu && isHomePage" ref="menu" class="dropdown-menu">
      <button @click="goToAboutResuvo" class="menu-button">About Resuvonia</button>
      <button @click="logout" class="menu-button">Logout</button>
    </div>

    <!-- Menu for other pages -->
    <div v-if="showMenu && !isHomePage" ref="menu" class="dropdown-menu">
      <button @click="goToTopicsPage" class="menu-button">Main Topics</button>
      <button @click="goToPrivateTopics" class="menu-button">Private Topics</button>
      <button @click="goToDonatePage" class="menu-button">Donate</button>
      <button @click="goToBuyVotes" class="menu-button">Buy Votes</button>
      <button @click="goToResources" class="menu-button">Get More Resources</button>
      <button @click="goToAboutResuvo" class="menu-button">About Resuvonia</button>
      <button @click="logout" class="menu-button">Logout</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBackButton: { type: Boolean, default: false },
    backText: { type: String, default: "Back" },
    showLogoutButton: { type: Boolean, default: true },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    isTopicsPage() {
      return this.$route.name === 'topics';
    },
    isLoginPage() {
      return this.$route.name === 'login';
    },
    isHomePage() {
      return this.$route.name === 'home';
    },
    isBooksPage() {
      return this.$route.name === 'books';
    },
    isArticlesPage() {
      return this.$route.name === 'articles';
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    closeMenu() {
      this.showMenu = false;
      document.removeEventListener('click', this.handleClickOutside);
    },
    emitBack() {
      this.$emit("back");
    },
    logout() {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("currentUser");
      this.$router.push("/login");
    },
    goToTopicsPage() {
      this.$router.push("/topics");
    },
    goToAboutResuvo() {
      this.$router.push("/about-resuvo");
    },
    goToResources() {
      this.$router.push("/resources");
    },
    goToPrivateTopics() {
      this.$router.push("/private-topics");
    },
    goToDonatePage() {
      this.$router.push("/donate");
    },
    goToBuyVotes() {
      this.$router.push("/buy-votes");
    },
    handleClickOutside(event) {
      const menuElement = this.$refs.menu;
      const buttonElement = this.$el.querySelector('.menu-button');

      if (this.showMenu && menuElement && !menuElement.contains(event.target) && !buttonElement.contains(event.target)) {
        this.closeMenu();
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left,
.right {
  display: flex;
  align-items: center;
}

.left {
  position: absolute;
  left: 20px;
}

.right {
  position: absolute;
  right: 20px;
}

.center {
  margin: 0 auto;
}

.logo {
  height: 50px;
  cursor: pointer;
}

.back-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
}

.menu-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.back-button:hover,
.menu-button:hover {
  background-color: #555;
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #333;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.dropdown-menu button {
  margin-bottom: 10px;
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #555;
}
</style>
