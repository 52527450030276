import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import HomePage from '@/components/HomePage.vue';
import TopicsPage from '@/components/TopicsPage.vue';
import TopicInTopicsPage from '@/components/TopicInTopicsPage.vue';
import SuggestionsPage from '@/components/SuggestionsPage.vue';
import AboutResuvoPage from '@/components/AboutResuvoPage.vue';
import FollowersPage from '@/components/FollowersPage.vue';
import FollowingPage from '@/components/FollowingPage.vue';
import UserTopicSuggestionsPage from '@/components/UserTopicSuggestionsPage.vue';
import PrivateTopicsPage from '@/components/PrivateTopicsPage.vue';
import PrivateSuggestionsPage from '@/components/PrivateSuggestionsPage.vue';
import ResourcesPage from '@/components/ResourcesPage.vue';
import BooksPage from '@/components/BooksPage.vue';
import BookDetails from '@/components/BookDetails.vue';
import ArticlesPage from '@/components/ArticlesPage.vue';
import ArticleDetails from '@/components/ArticleDetails.vue';
import BuyVotesPage from '@/components/BuyVotesPage.vue';
import DonatePage from '@/components/DonatePage.vue';
import NewsPage from '@/components/NewsPage.vue';
import NewsDetails from '@/components/NewsDetails.vue';
import SuccessPage from '@/components/SuccessPage.vue';
import FailurePage from '@/components/FailurePage.vue';
import UsersPage from '@/components/UsersPage.vue';
import UserTopicsPage from '@/components/UserTopicsPage.vue';
import UserSuggestionsPage from '@/components/UserSuggestionsPage.vue';
import UserTopicInTopicsPage from '@/components/UserTopicInTopicsPage.vue';
import ResearchPage from '@/components/ResearchPage.vue'; // Import ResearchPage
import ResearchDetails from '@/components/ResearchDetails.vue'; // Import ResearchDetails

const routes = [
  { path: '/', name: 'login', component: LoginPage },
  { path: '/home', name: 'home', component: HomePage },
  { path: '/topics', name: 'topics', component: TopicsPage },
  {
    path: '/topics/:parentTopicName',
    name: 'topic-in-topics',
    component: TopicInTopicsPage,
    props: true,
  },
  {
    path: '/suggestions/:parentTopicName/:topicName',
    name: 'suggestions',
    component: SuggestionsPage,
    props: true,
  },
  {
    path: '/about-resuvo',
    name: 'about-resuvo',
    component: AboutResuvoPage,
  },
  {
    path: '/followers/:username',
    name: 'followers',
    component: FollowersPage,
    props: true,
  },
  {
    path: '/following/:username',
    name: 'following',
    component: FollowingPage,
    props: true,
  },
  {
    path: '/user-topic-suggestions/:username/:topicName',
    name: 'user-topic-suggestions',
    component: UserTopicSuggestionsPage,
    props: true,
  },
  {
    path: '/private-topics',
    name: 'private-topics',
    component: PrivateTopicsPage,
  },
  {
    path: '/private-suggestions/:topicName',
    name: 'private-suggestions',
    component: PrivateSuggestionsPage,
    props: true,
  },
  {
    path: '/resources',
    name: 'resources',
    component: ResourcesPage,
  },
  {
    path: '/books',
    name: 'books',
    component: BooksPage,
  },
  {
    path: '/books/:bookId',
    name: 'book-details',
    component: BookDetails,
    props: true,
  },
  {
    path: '/articles',
    name: 'articles',
    component: ArticlesPage,
  },
  {
    path: '/articles/:articleId',
    name: 'article-details',
    component: ArticleDetails,
    props: true,
  },
  {
    path: '/buy-votes',
    name: 'buy-votes',
    component: BuyVotesPage,
  },
  {
    path: '/donate',
    name: 'donate',
    component: DonatePage,
  },
  {
    path: '/news',
    name: 'news',
    component: NewsPage,
  },
  {
    path: '/news/:newsId',
    name: 'news-details',
    component: NewsDetails,
    props: true,
  },
  {
    path: '/research',
    name: 'research',
    component: ResearchPage, // Add ResearchPage route
  },
  {
    path: '/research/:studyId',
    name: 'research-details',
    component: ResearchDetails, // Add ResearchDetails route
    props: true,
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessPage,
  },
  {
    path: '/failure',
    name: 'failure',
    component: FailurePage,
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage,
  },
  {
    path: '/users/:username/topics',
    name: 'user-topics',
    component: UserTopicsPage,
    props: true,
  },
  {
    path: '/users/:username/topics/:topicName',
    name: 'user-topic-in-topics',
    component: UserTopicInTopicsPage,
    props: true,
  },
  {
    path: '/users/:username/topics/:topicName/suggestions',
    name: 'user-suggestions',
    component: UserSuggestionsPage,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard to handle authentication
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('jwtToken');
  console.log('Navigating to:', to.name);
  console.log('Token present:', !!token);

  const publicPages = ['login'];
  const isPublicPage = publicPages.includes(to.name);

  if (!token && !isPublicPage) {
    console.log('Redirecting to login page');
    next({ name: 'login' });
  } else {
    console.log('Accessing public or authenticated page');
    next();
  }
});

export default router;
