<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Private Topics" 
      :showLogoutButton="true" 
      @back="goBackToPrivateTopics"
    />
    <div class="content">
      <h1>Private Suggestions for {{ formattedTopicName }}</h1>
      <div class="new-suggestion-container">
        <textarea v-model="newSuggestion" placeholder="Write your suggestion..." maxlength="5000"></textarea>
        <button @click="addSuggestion" class="submit-button">Submit</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
      <div class="sorting-buttons">
        <button @click="sortByMostRecent" :class="{ active: sortOption === 'mostRecent' }">Most Recent</button>
        <button @click="sortByMostVoted" :class="{ active: sortOption === 'mostVoted' }">Most Voted</button>
      </div>
      <ul class="suggestions-list">
        <li v-for="suggestion in sortedSuggestions" :key="suggestion._id" class="suggestion-item">
          <div class="suggestion-box">
            <div class="suggestion-details">
              <p><strong>{{ suggestion.username }}</strong></p>
              <p><strong>Submitted:</strong> {{ new Date(suggestion.timestamp).toLocaleString() }}</p>
              <p v-if="suggestion.editedTimestamp"><strong>Edited:</strong> {{ new Date(suggestion.editedTimestamp).toLocaleString() }}</p>
              <p><strong>Resources:</strong> {{ suggestion.resource || 'No resource' }}</p>
              <div class="suggestion-text">
                <p v-if="editingId !== suggestion._id">{{ suggestion.text }}</p>
                <textarea v-else v-model="editingText" maxlength="5000"></textarea>
              </div>
              <div class="actions">
                <button @click="vote(suggestion, 1)" :disabled="votesGiven[suggestion._id]">Vote ({{ suggestion.votes || 0 }})</button>
                <button @click="toggleExtraVote(suggestion._id)">Extra Votes</button>
                <div v-if="extraVoteVisible === suggestion._id">
                  <input type="number" v-model="extraVoteCount" :max="extraVotes" min="1" placeholder="Extra votes" />
                  <button @click="submitExtraVotes(suggestion)">Submit Extra Votes</button>
                </div>
                <button v-if="editingId !== suggestion._id && suggestion.username === currentUser" @click="startEditing(suggestion)">Edit</button>
                <button v-if="suggestion.username === currentUser" @click="deleteSuggestion(suggestion._id)">Delete</button>
                <div v-if="editingId === suggestion._id">
                  <button @click="saveEdit(suggestion._id)">Save</button>
                  <button @click="cancelEdit">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li v-if="sortedSuggestions.length === 0">
          <p>No suggestions available for this topic yet. Be the first to submit one!</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import axiosInstance from '@/axiosConfig';

export default {
  components: { Header },
  props: {
    topicName: {
      type: String,
      required: true,
    },
  },
  data() {
    let storedUser = localStorage.getItem('currentUser');
    return {
      newSuggestion: '',
      suggestions: [],
      editingId: null,
      editingText: '',
      sortOption: 'mostRecent',
      errorMessage: '',
      currentUser: storedUser ? JSON.parse(storedUser).username : 'guest',
      extraVotes: 0,
      extraVoteCount: 0,
      extraVoteVisible: null,
      votesGiven: {}, // Tracks votes given on suggestions
    };
  },
  computed: {
    sortedSuggestions() {
      if (this.sortOption === 'mostVoted') {
        return [...this.suggestions].sort((a, b) => b.votes - a.votes);
      }
      return [...this.suggestions].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    },
    formattedTopicName() {
      return this.topicName;
    }
  },
  async created() {
    await this.loadSuggestions();
    await this.getExtraVotes();
  },
  methods: {
    async vote(suggestion, voteCount = 1) {
      try {
        if (this.votesGiven[suggestion._id]) {
          this.errorMessage = 'You have already voted on this suggestion.';
          return;
        }
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/private-suggestions/${suggestion._id}/vote`, { voteCount }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        suggestion.votes += voteCount; // Update votes locally
        this.votesGiven[suggestion._id] = true; // Mark as voted
        this.errorMessage = ''; // Clear any error message
      } catch (error) {
        this.errorMessage = 'Failed to vote for suggestion.';
        console.error('Error voting:', error.response?.data || error.message);
      }
    },

    toggleExtraVote(suggestionId) {
      this.extraVoteVisible = this.extraVoteVisible === suggestionId ? null : suggestionId;
    },

    async submitExtraVotes(suggestion) {
      const voteCount = parseInt(this.extraVoteCount, 10); 

      if (isNaN(voteCount) || voteCount < 1 || voteCount > this.extraVotes) {
        this.errorMessage = "Invalid number of extra votes.";
        return;
      }

      try {
        await this.vote(suggestion, voteCount);
        this.extraVotes -= voteCount;
        this.extraVoteVisible = null;
        this.extraVoteCount = 0;
      } catch (error) {
        this.errorMessage = 'Failed to submit extra votes.';
        console.error('Error submitting extra votes:', error.response?.data || error.message);
      }
    },

    async addSuggestion() {
      if (!this.newSuggestion.trim()) {
        this.errorMessage = 'Suggestion cannot be empty.';
        return;
      }
      try {
        const token = localStorage.getItem('jwtToken');
        const storedUser = JSON.parse(localStorage.getItem('currentUser'));
        const resource = storedUser?.resource || '';

        const response = await axiosInstance.post(`/private-suggestions/${this.topicName}`, {
          text: this.newSuggestion.trim(),
          resource,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.suggestions.push(response.data.suggestion);
        this.newSuggestion = '';
        this.errorMessage = '';
      } catch (error) {
        this.errorMessage = 'Failed to submit suggestion.';
        console.error('Error submitting suggestion:', error.response?.data || error.message);
      }
    },

    async loadSuggestions() {
      try {
        const response = await axiosInstance.get(`/private-suggestions/${this.topicName}`);
        this.suggestions = Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        this.errorMessage = 'Failed to load suggestions.';
        console.error('Error loading suggestions:', error.response?.data || error.message);
      }
    },

    async getExtraVotes() {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axiosInstance.get('/users/extra-votes', {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.extraVotes = response.data.extraVotes || 0;
      } catch (error) {
        this.errorMessage = 'Failed to fetch extra votes.';
        console.error('Error fetching extra votes:', error.response?.data || error.message);
      }
    },

    async startEditing(suggestion) {
      this.editingId = suggestion._id;
      this.editingText = suggestion.text;
    },

    async saveEdit(suggestionId) {
      if (!this.editingText.trim()) {
        this.errorMessage = 'Suggestion text cannot be empty.';
        return;
      }
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.put(`/private-suggestions/${suggestionId}`, { text: this.editingText }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const suggestion = this.suggestions.find(s => s._id === suggestionId);
        suggestion.text = this.editingText;
        this.editingId = null;
        this.editingText = '';
      } catch (error) {
        this.errorMessage = 'Failed to edit suggestion.';
        console.error('Error editing suggestion:', error.response?.data || error.message);
      }
    },

    async deleteSuggestion(suggestionId) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axiosInstance.delete(`/private-suggestions/${suggestionId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.suggestions = this.suggestions.filter(s => s._id !== suggestionId);
      } catch (error) {
        this.errorMessage = 'Failed to delete suggestion.';
        console.error('Error deleting suggestion:', error.response?.data || error.message);
      }
    },

    cancelEdit() {
      this.editingId = null;
      this.editingText = '';
    },

    sortByMostRecent() {
      this.sortOption = 'mostRecent';
    },

    sortByMostVoted() {
      this.sortOption = 'mostVoted';
    },

    goBackToPrivateTopics() {
      this.$router.push('/private-topics');
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 60px;
  padding: 20px;
  overflow-y: auto;
}
.new-suggestion-container {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}
textarea {
  width: 100%;
  max-width: 800px;
  height: 150px;
  padding: 10px;
  box-sizing: border-box;
}
.submit-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  max-width: 800px;
}
.submit-button:hover {
  background-color: #555;
}
.suggestions-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 900px;
}
.suggestion-item {
  margin: 10px 0;
}
.suggestion-box {
  border: 1px solid #ccc;
  padding: 10px;
}
.suggestion-details {
  text-align: left;
}
.suggestion-text {
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.actions {
  margin-top: 5px;
  display: flex;
  gap: 10px;
}
.actions button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}
.actions button:hover {
  background-color: #555;
}
.sorting-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
}
.sorting-buttons button {
  margin: 0 10px;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.sorting-buttons button:hover {
  background-color: #555;
}
.active {
  background-color: #555;
}
.error-message {
  color: red;
  margin-top: 10px;
}
</style>
