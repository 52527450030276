<template>
  <div class="container">
    <Header 
      :showBackButton="true" 
      backText="Back to Previous Page" 
      :showLogoutButton="true" 
      @back="goBackToPreviousPage" 
    />
    <div class="content">
      <h1>Get More Resources From The Sources Below</h1>
      <div class="resource-box" @click="goToBooks">
        Books
      </div>
      <div class="resource-box" @click="goToArticles">
        Articles
      </div>
      <div class="resource-box" @click="goToNews">
        News
      </div>
      <div class="resource-box" @click="goToResearch">
        Research
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: {
    Header,
  },
  methods: {
    goBackToPreviousPage() {
      this.$router.go(-1);
    },
    goToBooks() {
      this.$router.push({ name: 'books' });
    },
    goToArticles() {
      this.$router.push({ name: 'articles' });
    },
    goToNews() {
      this.$router.push({ name: 'news' });
    },
    goToResearch() {
      this.$router.push({ name: 'research' });
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  flex: 1;
}

.resource-box {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  max-width: 200px;
  margin: 20px 0;
}

.resource-box:hover {
  background-color: #555;
}

.payment-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
}

.pay-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.pay-button:hover {
  background-color: #555;
}

.error-message {
  color: red;
}
</style>
